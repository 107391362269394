import Fuse from 'fuse.js';

class FuseSearch {
    constructor(type) {
        this.contentSearch = this.contentData = [];
        this.type = type;
    }

    async getContent() {
        let result = await axios.get('/guest/api/activity_search?type=' + this.type);

        return await result.data;
    }

    async search(searchText = '') {
        let options = {
            shouldSort: true,
            includeScore: true,
            tokenize: false,
            matchAllTokens: true,
            threshold: 0.4,
            location: 0,
            distance: 2000,
            minMatchCharLength: 3,
            keys: [
                {
                    name: 'title',
                    weight: 0.7
                },
                {
                    name: 'description',
                    weight: 0.3
                }
            ]
        };

        let contentData = await this.getContent();

        for (let i in contentData) {
            this.contentSearch.push(contentData[i]);
        }

        this.contentData = _.flatten(this.contentSearch);

        let fuse = new Fuse(this.contentData, options);
        return fuse.search(searchText);
    }
}

export default FuseSearch;
