<template>
    <div class="onboard-footer onboard-quiz__footer">
        <a href="#" class="onboard-footer__link"
            >Why am I answering these questions
            <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                <line x1="12" y1="17" x2="12.01" y2="17"></line></svg
        ></a>
    </div>
</template>
<script>
import collectorActions from '../../../mixins/collectorActions';

export default {
    name: 'pathway-nodes-footer',
    props: [],
    mixins: [collectorActions],
    data() {
        return {};
    },
    computed: {},
    methods: {},
    watch: {}
};
</script>

<style lang="scss"></style>
