import { render, staticRenderFns } from "./PathwayFeedback.vue?vue&type=template&id=d117dad4&scoped=true&"
import script from "./PathwayFeedback.vue?vue&type=script&lang=js&"
export * from "./PathwayFeedback.vue?vue&type=script&lang=js&"
import style0 from "./PathwayFeedback.vue?vue&type=style&index=0&id=d117dad4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d117dad4",
  null
  
)

export default component.exports