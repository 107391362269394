<template>
    <div>
        <main-header></main-header>
        <div class="section">
            <div class="grid-cont content-cont">
                <transition-group name="fade" mode="out-in">
                    <component
                        :is="component"
                        key="transition0"
                        :similarArticles="similarArticles"
                    ></component>
                    <content-feedback
                        key="transition1"
                        :content="originalContent"
                        :existing-content-feedback="existingContentFeedback"
                    ></content-feedback>
                </transition-group>

                <similar-content
                    :similar-content="similarContent"
                    class="mt--250"
                ></similar-content>
            </div>
        </div>
        <pathway-footer></pathway-footer>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import bookmarkActions from '../../../mixins/bookmarkActions';

export default {
    name: 'content-view',
    props: [
        'originalGuest',
        'originalStaticContent',
        'originalContent',
        'similarContent',
        'similarArticles',
        'existingContentFeedback'
    ],
    mixins: [collectorActions, bookmarkActions],
    mounted() {
        this.$store.commit('SET_GUEST', this.originalGuest);
        this.$store.commit('SET_STATIC_CONTENT', this.originalStaticContent);
        this.loadContent(this.originalContent);
        this.openContentAction(this.originalContent.id);
    },
    data() {
        return {
            component: '',
            typeMap: {
                Iframe: 'IframeContent',
                Video: 'VideoContent',
                QuestionSet: 'QuestionSetContent',
                Text: 'TextContent',
                Article: 'ArticleContent'
            }
        };
    },
    computed: {
        ...mapGetters({
            content: 'content',
            guest: 'guest',
            staticContent: 'staticContent'
        })
    },
    methods: {
        loadContent(content) {
            content = content || this.originalContent;

            this.component = this.originalContent.multi_content
                ? 'MultiContent'
                : this.typeMap[content.content_type.name];

            this.$store.commit('SET_CONTENT', content);
        }
    },
    watch: {}
};
</script>

<style lang="scss">
.resource__action--book.active {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="%2323202E" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>');
}

.content-cont {
    margin-top: 80px;
    min-height: 100%;
    height: 100%;
}
</style>
