<template>
    <div class="rfeed-welcome" v-if="!guest.welcome_message_viewed">
        <h4 class="rfeed-welcome__title">Welcome to your resource feed!</h4>
        <p class="p p--sm rfeed-welcome__subtitle">
            We’ve personalized your resource feed based on your preferences. You can always edit
            your preferences by visiting the <a href="#">Account Settings</a> section.
        </p>
        <button type="button" class="rfeed-welcome__close" @click="closeWelcomeMessage">
            <svg
                viewBox="0 0 24 24"
                width="20"
                height="20"
                stroke="currentColor"
                stroke-width="2.4"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="css-i6dzq1"
            >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
        </button>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'feed-welcome',
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {
        closeWelcomeMessage() {
            this.$store.dispatch('closeFeedWelcome');
        }
    },
    watch: {}
};
</script>

<style lang="scss"></style>
