<template>
    <div class="rfeed-footer">
        <template v-for="(content, index) in staticContent">
            <a :href="`/aarp/${content.slug}`" class="rfeed-footer__link">{{ content.title }}</a>
            <div v-if="index + 1 < staticContent.length" class="rfeed-footer__bull"></div>
        </template>

        <div></div>

        <img
            src="img/logo.png"
            srcset="img/logo@2x.png"
            width=""
            height="10"
            alt="AARP"
            class="rfeed-footer__logo"
        />
        <a href="http://aarp.org/" class="rfeed-footer__link rfeed-footer__link--alt">AARP Inc</a>
        <div class="rfeed-footer__bull"></div>
        <a class="rfeed-footer__link rfeed-footer__link--alt">All Rights Reserved</a>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'feed-footer',
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            staticContent: 'staticContent'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss"></style>
