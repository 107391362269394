$(document).ready(function() {
  /**/
  /* rfeed welcome */
  /**/
  $('.rfeed-welcome__close').on('click', function() {
    $(this).parent().slideUp(250);
  });


  /**/
  /* rfeed lessons */
  /**/
  $('.rfeed-lessons__close').on('click', function() {
    $(this).parent().slideUp(250);
  });


  /**/
  /* admin card */
  /**/
  $('.admin-card__toggle').on('click', function() {
    $(this).toggleClass('active');
    $(this).closest('.admin-card').find('.admin-card__spoiler').slideToggle(250);
  });


  /**/
  /* settings filter */
  /**/
  $('.settings-filter__toggle').on('click', function() {
    $(this).parent().toggleClass('active');
  });
  $(document).mouseup(function(e)
  {
    var container = $('.settings-filter');
    if (!container.is(e.target) && container.has(e.target).length === 0)
    {
      container.removeClass('active');
    }
  });


  /**/
  /* flow drawer filter */
  /**/
  $('.flow-drawer__filter-button').on('click', function() {
    $(this).closest('.flow-drawer').find('.flow-filter').toggleClass('active');
  });
  $(document).mouseup(function(e)
  {
    var container = $('.flow-filter');
    if (!container.is(e.target) && container.has(e.target).length === 0)
    {
      container.removeClass('active');
    }
  });


  /**/
  /* flow content type */
  /**/
  $('.flow-content-type__toggle').on('click', function() {
    $(this).parent().toggleClass('active');
  });
  $('.flow-content-type input').on('change', function() {
    const ths = $(this);
    ths.closest('.flow-content-type__dropdown').prev().text(ths.val());
  });
  $(document).mouseup(function(e)
  {
    var container = $('.flow-content-type');
    if (!container.is(e.target) && container.has(e.target).length === 0)
    {
      container.removeClass('active');
    }
  });


  /**/
  /* tooltip */
  /**/
  $('body').append('<div id="tooltip" class="tooltip"></div>');
  $('[data-tooltip]').hover(function() {
      var ths = $(this);
      $('#tooltip').html($(this).data('tooltip'));
      $('#tooltip').attr('class', 'tooltip active');
      $('#tooltip').css('top', ths.offset().top);
      $('#tooltip').css('left', ths.offset().left + ths.outerWidth()/2);
  }, function() {
      $('#tooltip').removeClass('active');
  });
});
