<template>
    <div>
        <div class="admin-header">
            <v-row>
                <div class="display-1 col-auto">Topics</div>
            </v-row>
        </div>

        <div class="admin-table">
            <v-data-table
                :headers="headers"
                :items="topics"
                :options.sync="options"
                :footer-props="{ 'items-per-page-options': [20, 50, 75, 100] }"
                :loading="loading"
                :items-per-page="20"
                class="elevation-1"
            >
                <template v-slot:no-data>
                    <div>No data</div>
                </template>
                <template v-if="topics.length" v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="item in items" :key="item.id">
                            <td>{{ item.name }}</td>
                            <td>
                                <v-row no-gutters align="center">
                                    <edit-button
                                        :url="`/admin/topics/${item.id}/edit`"
                                    ></edit-button>
                                    <delete-button
                                        v-if="authUser.admin && item.canDelete"
                                        @click.stop="deleteTopic(item)"
                                    ></delete-button>
                                </v-row>
                            </td>
                            <td>{{ renderLocalTime(item.created_at) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>
<script>
import Time from '../../../mixins/Time';
import DeleteButton from '../buttons/DeleteButton';

export default {
    name: 'topic-list',
    components: { DeleteButton },
    props: ['allTopics', 'authUser'],
    mixins: [Time],
    mounted() {
        this.topics = this.allTopics;
    },
    data() {
        return {
            topics: [],
            loading: false,
            total: 0,
            headers: [
                { text: 'Name', value: 'name', sortable: true },
                { text: 'Actions', value: 'actions', sortable: false },
                { text: 'Created at', value: 'created_at', sortable: true }
            ],
            options: { sortDesc: [true], sortBy: ['created_at'] }
        };
    },
    methods: {
        deleteTopic(topic) {
            if (confirm('Are you sure you want to delete topic ' + topic.name + '?')) {
                axios
                    .delete('/admin/topics/' + topic.id)
                    .then(res => {
                        let index = this.topics.findIndex(x => x.id === topic.id);

                        if (index > -1) {
                            this.topics.splice(index, 1);
                        }
                    })
                    .catch(err => {
                        alert(err.response.data.errors.topic[0]);
                    });
            }
        }
    },
    watch: {}
};
</script>
