import { cookie } from '../helpers/cookie';
import { getUniqueId } from '../helpers/getUniqueId';

const Cookie = new cookie('athena');

export default {
    methods: {
        storeGuestCookie: function(uuid) {
            Cookie.setCookie('guest_uuid', uuid, { expires: 1 });
        },
        setCookie: function(cookieName, cookieValue, expiration) {
            Cookie.setCookie(cookieName, cookieValue, { expires: expiration });
        },
        getCookie: function(type) {
            return Cookie.getCookie(type);
        },
        removeCookie: function(name) {
            Cookie.expire();
        }
    }
};
