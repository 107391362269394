<template>
    <div class="onboard-quiz__cont">
        <h4>Are you interested in this resource?</h4>

        <div class="onboard-interest">
            <div
                class="onboard-interest__item"
                :class="{
                    active: currentIndex === index,
                    discard: discardedIndexes.includes(index),
                    saved: savedIndexes.includes(index)
                }"
                v-for="(content, index) in multiContent"
            >
                <div class="onboard-interest__figure">
                    <div
                        :style="{ backgroundImage: 'url(' + content.thumbnail_xs + ')' }"
                        style="width:388px;height:128px;overflow:hidden;background-position:center;background-size:cover;background-repeat: no-repeat;"
                        class="onboard-interest__image"
                    >
                        &nbsp;
                    </div>
                    <div class="onboard-interest__badge">{{ content.content_type.name }}</div>
                </div>
                <div class="onboard-interest__cont">
                    <h5 class="onboard-interest__name">{{ content.title }}</h5>
                    <p class="p p--sm onboard-interest__summary">{{ content.description }}</p>
                </div>
            </div>

            <div class="grid-row grid-row--smg grid-row--aic grid-row--jcc">
                <div class="grid-col">
                    <button
                        type="button"
                        class="button button--tertiary onboard-interest__action onboard-interest__action--no"
                        @click="contentDiscarded"
                    >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2.4"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                        <span>Discard</span>
                    </button>
                </div>
                <div class="grid-col">
                    <button
                        type="button"
                        class="button button--secondary onboard-interest__action onboard-interest__action--yes"
                        @click="contentSaved"
                    >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2.4"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                        <span>Save</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="mt--250 onboard-hotkeys">
            Keyboard: <span class="onboard-hotkeys__button">Y</span> for Yes,
            <span class="onboard-hotkeys__button">N</span> for No.
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    props: ['revertChoice'],
    mixins: [collectorActions],
    data() {
        return {
            currentIndex: 0,
            savedIndexes: [],
            discardedIndexes: [],
            multiContent: []
        };
    },
    mounted() {
        this.multiContent.splice(0, this.multiContent.length, ...this.node.multi_content_data);
    },
    created() {
        window.addEventListener('keypress', this.selectContent);
    },
    destroyed() {
        window.removeEventListener('keypress', this.selectContent);
    },
    methods: {
        contentDiscarded() {
            this.discardedIndexes.push(this.currentIndex);

            this.$store.dispatch('removeContentBookmark', this.multiContent[this.currentIndex]);
            this.discardBookmarkAction(this.multiContent[this.currentIndex], this.pathway.id);

            if (this.currentContentIsLast) {
                setTimeout(() => {
                    this.$emit('loadNext');
                }, 200);
            }

            this.currentIndex++;
        },
        contentSaved() {
            this.savedIndexes.push(this.currentIndex);

            this.$store.dispatch('bookmarkContent', this.multiContent[this.currentIndex]);
            this.bookmarkContentAction(this.multiContent[this.currentIndex], this.pathway.id);

            if (this.currentContentIsLast) {
                setTimeout(() => {
                    this.$emit('loadNext');
                }, 200);
            }

            this.currentIndex++;
        },
        selectContent(event) {
            if (event.keyCode === 110) {
                this.contentDiscarded();
            }

            if (event.keyCode === 121) {
                this.contentSaved();
            }
        },
        deleteChoice() {
            this.currentIndex--;
            this.savedIndexes.splice(this.currentIndex, 1);
            this.discardedIndexes.splice(this.currentIndex, 1);

            this.$store.dispatch('removeContentBookmark', this.multiContent[this.currentIndex]);
        }
    },
    computed: {
        ...mapGetters({
            pathway: 'pathway',
            guest: 'guest',
            node: 'node',
            content: 'content'
        }),
        currentContentIsLast() {
            return this.currentIndex + 1 >= this.multiContent.length;
        }
    },
    watch: {
        revertChoice: {
            handler() {
                if (this.revertChoice && this.currentIndex > 0) {
                    this.deleteChoice();
                    this.$emit('updateRevertChoice', false);
                } else if (this.revertChoice && this.currentIndex === 0) {
                    this.$emit('updateRevertChoice', false);
                    this.$emit('backStep');
                }
            },
            immediate: true
        }
    }
};
</script>
