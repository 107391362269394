<template>
    <div id="flow-editor">
        <v-overlay :value="pathway.active" absolute z-index="9999">
            <v-alert color="error"
                >The pathway flow cannot be modified while the pathway is active.</v-alert
            >
        </v-overlay>
        <v-overlay :value="overlay" absolute z-index="9999">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-row no-gutters>
            <flow-drawer
                :all-topics="allTopics"
                :pathway="pathway"
                :types="types"
                :zip-file-names="zipFileNames"
                :settings="settings"
                @overlayToggle="overlay = !overlay"
                @drawerExpanded="expandedDrawer = !expandedDrawer"
            ></flow-drawer>

            <v-col>
                <div id="flow-frame">
                    <div
                        class="subframe"
                        ref="frame"
                        @drop="onDrop($event)"
                        @dragover.prevent
                        @dragenter.prevent
                    >
                        <template v-for="node in nodes">
                            <node
                                :id="`node-${node.id}`"
                                :node="node"
                                :ref="`node${node.id}`"
                                :key="node.id"
                                :size="nodeSize"
                                v-if="node.deleted === undefined"
                                :style="{
                                    transform:
                                        'translate(' +
                                        getNodeX(node) +
                                        'px,' +
                                        getNodeY(node) +
                                        'px)'
                                }"
                                :class="{ hasError: errorNodes.includes(node.id) }"
                                @delete="deleteNodeRequest(node.id)"
                                @openRules="openRulesModal(node)"
                                @onDropEvent="onDrop($event, node)"
                                @showMultiContentInfo="showNodeInfo(node)"
                            ></node>
                        </template>
                        <svg id="nodesSvg">
                            <line
                                v-for="line in lines"
                                :x1="line[0]"
                                :y1="line[1]"
                                :x2="line[2]"
                                :y2="line[3]"
                                class="lineline"
                                :class="{ linelineHover: hovering[line[5]] }"
                                :style="{ stroke: line[4] }"
                            />
                            <line
                                v-if="snap"
                                v-for="n in 100"
                                :x1="n * blockSize"
                                :y1="0"
                                :x2="n * blockSize"
                                :y2="1400"
                                class="gridline"
                            />

                            <defs>
                                <pattern
                                    id="polka-dots"
                                    x="0"
                                    y="0"
                                    width="2"
                                    height="2"
                                    patternUnits="userSpaceOnUse"
                                >
                                    <circle fill="#ccc" cx="1" cy="1" r="0.5"></circle>
                                </pattern>
                            </defs>

                            <rect
                                x="0"
                                y="0"
                                width="100%"
                                height="100%"
                                fill="url(#polka-dots)"
                            ></rect>
                        </svg>
                    </div>

                    <div class="zoom-cont">
                        <v-btn
                            depressed
                            icon
                            @click="adjustZoomLevel(1)"
                            :disabled="zoomLevel === 3"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#000000"
                            >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                            </svg>
                        </v-btn>

                        <v-btn
                            depressed
                            icon
                            @click="adjustZoomLevel(-1)"
                            :disabled="zoomLevel === 1"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#000000"
                            >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M19 13H5v-2h14v2z" />
                            </svg>
                        </v-btn>
                    </div>

                    <div id="line-wrapper"></div>
                </div>
            </v-col>
        </v-row>

        <split-rules-modal
            v-if="rulesModal"
            v-bind="rulesModalProperties"
            @splitRulesUpdated="updateSplitRulesForNode($event)"
            @modalClosed="rulesModal = false"
        ></split-rules-modal>

        <node-info-modal
            v-if="showNodeInfoModal"
            v-bind="nodeInfoModal"
            @modalClosed="showNodeInfoModal = false"
            @deleteContent="removeMultiContentRequest($event)"
        ></node-info-modal>
    </div>
</template>
<script>
//UNCOMMENT FOR PAPER const paper = require('paper')
import OrganizeFlow from '../../../mixins/OrganizeFlow';
import { mapGetters } from 'vuex';
import FlowDrawer from './FlowDrawer';
import Node from '../nodes/Node';
import LeaderLine from 'leader-line-vue';

export default {
    name: 'pathway-flow',
    components: { Node, FlowDrawer, LeaderLine },
    //components: { },
    props: ['pathway', 'types', 'settings', 'zipFileNames', 'allTopics'],
    mixins: [OrganizeFlow],
    mounted() {
        let counter = 0;
        let nodesLength = this.pathway.nodes.length;
        this.wrapperPosition();

        this.pathway.nodes.forEach(node => {
            node.next = node.children ?? [];

            this.nodes.push(
                this.createNode(
                    node.id,
                    node.next,
                    node.settings.x,
                    node.settings.y,
                    node.content_id,
                    false,
                    node
                )
            );

            counter++;

            if (counter === nodesLength) {
                this.drawLines();
            }
        });

        if (this.zoomLevel === 2) {
            this.nodeSize = 'lg';
            this.blockSize = 200;
        } else if (this.zoomLevel === 3) {
            this.nodeSize = 'xl';
            this.blockSize = 350;
        }
    },
    data() {
        return {
            //temporary var to hold an id counter; we'll replace with ids from backend later
            tracers: 1,
            overlay: false,
            maxColumnIndex: 0,
            blockSize: 100,
            snap: true,
            nodes: [],
            lines: [],
            hovering: {},
            rulesModalProperties: {},
            rulesModal: false,
            nodeInfoModal: {},
            showNodeInfoModal: false,
            expandedDrawer: false,
            nodeSize: 'md' // available sizes: md, lg, xl
        };
    },
    computed: {
        ...mapGetters({
            content: 'contentList',
            errorNodes: 'errorNodes',
            zoomLevel: 'zoomLevel'
        }),
        hasDanglingNodes() {
            let danglingNode = false;
            this.nodes.forEach(node => {
                if (node.next.length > 0 || node.origin) {
                    return;
                } else {
                    let parentIndex = this.nodes.findIndex(x => x.next.includes(node.id));
                    if (parentIndex === -1) {
                        danglingNode = true;
                    }
                }
            });

            return danglingNode;
        }
    },
    methods: {
        wrapperPosition() {
            // prepare for lines
            let elmWrapper = document.getElementById('line-wrapper');

            // Move to the origin of coordinates as the document
            elmWrapper.style.transform = 'none';

            let rectWrapper = elmWrapper.getBoundingClientRect();

            // Move to the origin of coordinates as the document
            elmWrapper.style.transform =
                'translate(-' +
                (rectWrapper.left + pageXOffset) +
                'px, -' +
                (rectWrapper.top + pageYOffset) +
                'px)';
        },
        updateSplitRulesForNode(event) {
            let index = _.findIndex(this.nodes, { id: event.node_id });
            this.nodes[index].split_rules = event.rules;
        },
        showNodeInfo(node) {
            this.showNodeInfoModal = true;
            this.nodeInfoModal.node = node;
            this.nodeInfoModal.dialog = true;
        },
        createNodeRequest(data) {
            this.$emit('overlay', true);

            return axios
                .post('/admin/nodes', data)
                .then(response => {
                    this.$emit('overlay', false);
                    this.pathway.nodes = response.data.pathway.nodes;

                    return response.data.node;
                })
                .catch(e => {
                    this.$emit('overlay', false);
                });
        },
        adjustZoomLevel(zoom) {
            this.$store.commit('SET_ZOOM_LEVEL', this.zoomLevel + zoom);

            switch (this.zoomLevel) {
                case 2:
                    this.nodeSize = 'lg';
                    this.blockSize = 200;
                    this.distanceNodes('y1', 82);
                    break;
                case 3:
                    this.nodeSize = 'xl';
                    this.blockSize = 350;
                    this.distanceNodes('y2', 132);
                    break;
                default:
                    this.nodeSize = 'md';
                    this.blockSize = 100;
                    break;
            }

            this.drawLines();
        },
        distanceNodes(verticalSelector, size) {
            let nodeColumns = _.groupBy(this.nodes, 'column');

            function pairwise(arr, func) {
                for (let i = 0; i < arr.length - 1; i++) {
                    func(arr[i], arr[i + 1]);
                }
            }

            Object.values(nodeColumns).forEach(nodes => {
                if (nodes.length > 1) {
                    nodes = _.sortBy(nodes, [verticalSelector]);

                    pairwise(nodes, (current, next) => {
                        let diff = Math.abs(current[verticalSelector] - next[verticalSelector]);

                        if (diff < size) {
                            let topIndex = this.nodes.findIndex(node => node.id === current.id);
                            let bottomIndex = this.nodes.findIndex(node => node.id === next.id);
                            this.nodes[topIndex][verticalSelector] -= size / 2 + 30;
                            this.nodes[bottomIndex][verticalSelector] += size / 2 + 30;
                        }
                    });
                }
            });
        },
        removeMultiContentRequest(contentData) {
            let nodeIndex = this.nodes.findIndex(x => x.id === contentData.node_id);
            let contentKey = Object.keys(contentData)[0];

            let data = this.nodes[nodeIndex];
            data.content_id = data.contentId;
            data.remove_multi_content = contentKey;
            data.pathway_id = this.pathway.id;

            axios
                .put('/admin/nodes/' + this.nodes[nodeIndex].id, data)
                .then(response => {
                    this.$emit('overlay', false);

                    this.nodes[nodeIndex].multi_content = response.data.node.multi_content;
                    this.nodes[nodeIndex].content_id = response.data.node.content_id;
                    this.nodes[nodeIndex].content_id2 = response.data.node.content_id2;
                    this.nodes[nodeIndex].content_id3 = response.data.node.content_id3;

                    this.nodes.splice(nodeIndex, 1, this.nodes[nodeIndex]); // we must do this to update DOM state
                })
                .catch(e => {
                    this.$emit('overlay', false);
                });
        },
        updateNodeRequest(node) {
            node = this.calculateNodePositions(node, node.x, node.y);

            let data = {
                id: node.id,
                pathway_id: this.pathway.id,
                content_id: node.content.id,
                content_id2: node.content_id2,
                content_id3: node.content_id3,
                split_rules: node.split_rules ?? null,
                children: node.next,
                settings: {
                    x: node.x,
                    y: node.y
                }
            };

            this.$emit('overlay', true);

            axios
                .put('/admin/nodes/' + node.id, data)
                .then(response => {
                    this.$emit('overlay', false);
                    let index = _.findIndex(this.nodes, { id: response.data.node.id });
                    this.nodes[index].multi_content = response.data.node.multi_content;

                    this.nodes.splice(index, 1, this.nodes[index]); // we must do this to update DOM state
                })
                .catch(e => {
                    this.$emit('overlay', false);
                });
        },
        deleteNodeRequest(nodeId) {
            if (confirm('Delete node?')) {
                axios
                    .delete('/admin/nodes/' + nodeId)
                    .then(response => {
                        this.$emit('overlay', false);
                        let index = this.nodes.findIndex(x => x.id === nodeId);

                        if (index > -1) {
                            /**
                             *  we set a deleted property on the node rather than removing it from the array
                             *  so that the css transition does not move nodes around, which could confuse users
                             */
                            this.nodes[index].deleted = true;
                            this.nodes.splice(index, 1, this.nodes[index]);
                        }

                        this.drawLines();
                        let originNode = response.data.originNode;
                        if (originNode) {
                            let index = this.nodes.findIndex(x => x.id === originNode.id);
                            if (index > -1) {
                                this.nodes[index].origin = true;
                            }
                        }

                        // remove all entries of the nodeId from the "next" array from parent nodes
                        if (response.data.removedFromParents.length) {
                            response.data.removedFromParents.forEach(parentId => {
                                let parentIndex = this.nodes.findIndex(x => x.id === parentId);

                                this.nodes[parentIndex].next.splice(
                                    this.nodes[parentIndex].next.indexOf(nodeId),
                                    1
                                );

                                if (this.nodes[parentIndex].split_rules) {
                                    this.nodes[parentIndex].split_rules.splice(
                                        this.nodes[parentIndex].split_rules.indexOf(nodeId),
                                        1
                                    );
                                }
                            });
                        }
                    })
                    .catch(e => {
                        this.$emit('overlay', false);
                    });
            }
        },
        createNode(id, next, x, y, contentId, sendRequest = true, nodeDbData = false) {
            let name = '';
            let thumbnail = '';
            let content = {};
            if (typeof id == 'undefined') id = null;
            if (typeof x == 'undefined') x = 0;
            if (typeof y == 'undefined') y = 0;
            if (typeof contentId != 'undefined') {
                if (!this.expandedDrawer) {
                    content = this.content.find(element => element.id == contentId);
                } else {
                    content = _.flatten(_.values(this.content)).find(
                        element => element.id == contentId
                    );
                }
                name = content.title;
                thumbnail = content.thumbnail;
            } else {
                contentId = null;
                name = 'node ' + id;
            }

            let nodeData = {
                id: id,
                contentId: content.id,
                next: next,
                x: nodeDbData ? x : x + 8,
                y: y,
                column: ~~(x / this.blockSize),
                plotted: false,
                origin: nodeDbData ? nodeDbData.origin : 0,
                name: name,
                content: nodeDbData ? nodeDbData.content : content,
                split_rules: nodeDbData.split_rules
            };

            nodeData = this.calculateNodePositions(nodeData, x, y);

            if (nodeDbData) {
                nodeData.content_id2 = nodeDbData.content_id2;
                nodeData.content_id3 = nodeDbData.content_id3;
                nodeData.multi_content = nodeDbData.multi_content;
            }

            if (this.nodes.length) {
                _.remove(this.nodes, {
                    deleted: true
                });
            }

            if (sendRequest) {
                this.createNodeRequest({
                    id: id,
                    pathway_id: this.pathway.id,
                    content_id: content.id,
                    origin: this.nodes.length === 0,
                    split_rules: null,
                    children: next,
                    settings: {
                        x: nodeData.x,
                        y: nodeData.y
                    }
                }).then(item => {
                    nodeData.id = item.id;
                    nodeData.origin = item.origin;
                    nodeData.content.questions = item.content.questions;
                });
            }

            return nodeData;
        },
        onDrop(evt, childNode) {
            let type = evt.dataTransfer.getData('type');
            let contentId = evt.dataTransfer.getData('contentId');
            let contentType = evt.dataTransfer.getData('contentType');

            let nodeId = evt.dataTransfer.getData('nodeId');

            //SNAPPING
            let dropX = evt.layerX;
            let dropY = evt.layerY;

            if (this.snap) {
                dropX -= dropX % this.blockSize;
            } else {
                //account for size of box; it positions top left corner
                dropX -= 50;
            }

            dropY -= 25;

            if (type === 'content' && typeof childNode != 'undefined') {
                let index = _.findIndex(this.nodes, { id: childNode.id });
                if (
                    contentType !== 'QuestionSet' &&
                    this.nodes[index].content.content_type.name !== 'QuestionSet'
                ) {
                    if (index > -1) {
                        this.nodes[index].multi_content = true;

                        if (!this.nodes[index].content_id2) {
                            this.nodes[index].content_id2 = parseInt(contentId);

                            this.updateNodeRequest(this.nodes[index]);
                        } else if (!this.nodes[index].content_id3) {
                            this.nodes[index].content_id3 = parseInt(contentId);

                            this.updateNodeRequest(this.nodes[index]);
                        } else {
                            // we don't allow more that 3 pieces of content per node
                            evt.preventDefault();
                            evt.stopPropagation();
                            return;
                        }
                    }
                } else {
                    evt.preventDefault();
                    evt.stopPropagation();

                    alert('QuestionSet can not be used in multi content node');

                    return;
                }

                evt.preventDefault();
                evt.stopPropagation();
            } else if (type == 'content' && typeof childNode == 'undefined') {
                this.nodes.push(this.createNode(nodeId, [], dropX, dropY, contentId));
            } else if (type == 'node' && typeof childNode != 'undefined') {
                //draw a line and connect the nodes
                let node = this.nodes.find(element => element.id == nodeId);

                // if you drag a node and drop it on itself, don't make it a child of itself
                if (node.id === childNode.id) {
                    evt.preventDefault();
                    evt.stopPropagation();
                    return;
                }

                // if the node is already a parent of the child node, do not push it again
                if (node.next.includes(childNode.id)) {
                    alert('This node is already connected');
                    evt.preventDefault();
                    evt.stopPropagation();
                    return;
                }

                // if the child node is mistakenly dragged to the parent, show an alert saying a child cannot be a parent of its parent
                if (childNode.next.includes(node.id)) {
                    alert('A child node cannot be a parent to its parent');
                    evt.preventDefault();
                    evt.stopPropagation();
                    return;
                }

                node.next.push(childNode.id);

                this.drawLines();

                this.updateNodeRequest(node);

                evt.preventDefault();
                evt.stopPropagation();
            } else if (type == 'node') {
                let node = this.nodes.find(element => element.id == nodeId);
                node.x = dropX + 8;
                node.y = dropY;

                this.updateNodeRequest(node);

                this.drawLines();
            }
        },
        drawLines: _.debounce(function() {
            // we use debounce here so $refs can be updated properly
            // setup line wrapper relative position
            this.wrapperPosition();
            let elmWrapper = document.getElementById('line-wrapper');

            // remove all lines
            [...document.getElementsByClassName('leader-line')].map(n => n && n.remove());

            for (let f = 0; f < this.nodes.length; f++) {
                let parentNode = this.nodes[f];
                for (let n = 0; n < parentNode.next.length; n++) {
                    let childNode = this.nodes.find(element => element.id == parentNode.next[n]);
                    let lineId = parentNode.id + '-' + childNode.id;
                    let lineIndex = this.lines.findIndex(x => x.id === lineId);

                    if (lineIndex > -1) {
                        this.lines.splice(lineIndex, 1);
                    }

                    if (
                        this.$refs['node' + parentNode.id][0] &&
                        this.$refs['node' + childNode.id][0]
                    ) {
                        let leaderLine = LeaderLine.setLine(
                            this.$refs['node' + parentNode.id][0].$el,
                            this.$refs['node' + childNode.id][0].$el,
                            {
                                color: '#555',
                                outline: false,
                                size: 2,
                                path: 'grid',
                                startPlug: 'square',
                                endPlug: 'arrow2',
                                startSocket: 'right',
                                endSocket: 'left',
                                startSocketGravity: 10,
                                endSocketGravity: 5
                            }
                        );

                        // move line from <body> to #line-wrapper so scroll doesn't overflow lines
                        let elmLine = document.querySelector('body > .leader-line:last-of-type');
                        elmWrapper.appendChild(elmLine);

                        this.lines.push({
                            id: lineId,
                            parentNodeId: parentNode.id,
                            childNodeId: childNode.id,
                            line: leaderLine
                        });
                    }
                }
            }
        }, 200),
        calculateNodePositions(node, dropX, dropY) {
            let column = ~~(dropX / this.blockSize);
            node.x = column * 100 + 8;
            node.x1 = column * 200 + 8;
            node.x2 = column * 350 + 8;

            node.y = node.y1 = node.y2 = dropY;

            return node;
        },
        calculatePositions() {
            for (let f = 0; f < this.nodes.length; f++) {
                let parentNode = this.nodes[f];
                for (let n = 0; n < parentNode.next.length; n++) {
                    let childNode = this.nodes.find(element => element.id == parentNode.next[n]);
                    let parentAboveChild = parentNode.y < childNode.y;

                    childNode.x1 = childNode.column * 200 + 8;
                    childNode.x2 = childNode.column * 350 + 8;
                    childNode.y1 = parentAboveChild ? childNode.y + 82 : childNode.y - 82;
                    childNode.y2 = parentAboveChild ? childNode.y + 132 : childNode.y - 132;
                }
            }
        },
        getNodeX(node) {
            switch (this.zoomLevel) {
                case 2:
                    return node.x1;
                case 3:
                    return node.x2;
                default:
                    return node.x;
            }
        },
        getNodeY(node) {
            switch (this.zoomLevel) {
                case 2:
                    return node.y1;
                case 3:
                    return node.y2;
                default:
                    return node.y;
            }
        },
        randomColor() {
            let colours = [
                'pink',
                'orange',
                'blue',
                'green',
                'red',
                'lightblue',
                'lightgreen',
                'purple'
            ];
            return colours[this.getRandomIntInclusive(0, colours.length - 1)];
        },
        getRandomIntInclusive(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
        },
        openRulesModal(node) {
            this.rulesModalProperties = {
                pathway: this.pathway,
                node: node,
                nodes: this.nodes,
                dialog: true
            };

            this.rulesModal = true;
        }
    }
};
</script>

<style lang="scss">
.hasError {
    border: 1px solid red;

    .flow-node__title {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.slide-around {
    transition: all 0.2s;
}

.lineline {
    stroke-width: 3;
    opacity: 0.5;
    z-index: 1000;

    &:hover {
        opacity: 1;
        stroke-width: 5;
        cursor: pointer;
    }
}

.linelineHover {
    stroke-width: 5;
    opacity: 0.8;
}

.gridline {
    stroke-width: 1;
    stroke: rgba(55, 55, 55, 0.1);
}

.toolbar {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 1005;
    height: 30px;
    overflow: hidden;
}

.node-pill {
    width: 86px;
    margin-left: 7px;
    height: 50px;
    border-radius: 15px;
    background-size: cover;
    position: absolute;
    display: flex;
    z-index: 1000;

    &:hover .delete-button {
        display: block;
    }

    .delete-button {
        position: absolute;
        top: -9px;
        right: -10px;
        outline: none;
        text-decoration: none;
        font-size: 4px;
        background: #fff;
        border-radius: 40px;
        padding: 2px;
        border: 1px solid #999;
        cursor: pointer;
        z-index: 220;
        display: none;

        &:hover {
            opacity: 0.8;
        }

        i {
            color: #fa0000;
            font-size: 16px;
        }
    }

    &:hover .info-button {
        display: block;
    }

    .info-button {
        position: absolute;
        top: 15px;
        right: -10px;
        outline: none;
        text-decoration: none;
        font-size: 4px;
        background: #fff;
        border-radius: 40px;
        padding: 2px;
        border: 1px solid #999;
        cursor: pointer;
        z-index: 220;
        display: none;

        &:hover {
            opacity: 0.8;
        }

        i {
            color: deepskyblue;
            font-size: 16px;
        }
    }

    &:hover .test-button {
        display: block;
    }

    .test-button {
        position: absolute;
        top: 18px;
        right: 70px;
        outline: none;
        text-decoration: none;
        font-size: 4px;
        background: #fff;
        border-radius: 40px;
        padding: 2px;
        border: 1px solid #999;
        cursor: pointer;
        z-index: 220;
        display: none;

        &:hover {
            opacity: 0.8;
        }

        i {
            color: deepskyblue;
            font-size: 16px;
        }
    }

    &:hover .split-rules-button {
        display: block;
    }

    .split-rules-button {
        position: absolute;
        cursor: pointer;
        left: 5px;
        top: -15px;
        display: none;

        &:hover {
            opacity: 0.8;
        }
    }
}

.node-text {
    font-size: 12px;
    text-align: center;
    line-height: 15px;
    vertical-align: middle;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
    width: 100%;
}

#flow-editor {
    position: relative;
    height: 100%;
    flex-direction: row;
    width: 100%;
    border: 1px solid #ccc;
    margin-bottom: 20px;
}

#flow-frame {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 200;
    overflow: auto;

    .subframe {
        height: 200%;
        width: 150%;
        position: relative;
        z-index: 200;

        svg {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 299;
            overflow: auto;
        }
    }
}

.menuable__content__active {
    z-index: 300 !important;
}

#line-wrapper {
    width: 0;
    height: 0;
    position: relative; /* Origin of coordinates for lines, and scrolled content (i.e. not `absolute`) */
}
</style>
