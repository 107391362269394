import Cookies from 'js-cookie';
import _ from 'lodash';

export class cookie {
    constructor(str) {
        if (!(this instanceof cookie)) {
            return new cookie(str);
        }

        this.config = {
            key: str,
            options: {
                expires: 3
            }
        };

        this.data = this.getCookie();

        return this;
    }

    get configuration() {
        return this.config;
    }

    getCookie(str, ignoreKey = false) {
        let data = {};

        if (Cookies.get(this.config.key) && !ignoreKey) {
            data = Cookies.getJSON(this.config.key);
        }

        if (str && typeof data === 'object' && typeof data !== null) {
            if (typeof data[str] !== 'undefined') {
                return data[str];
            } else {
                let nameEQ = str + '=';
                let ca = document.cookie.split(';');

                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
                }
            }
        }

        return data;
    }

    setCookie(str, value, options) {
        if (!arguments.length || !this.enabled()) return this;

        if (typeof str === 'string' && arguments.length >= 2) {
            this.data[str] = value ? value : null;
        } else if (typeof str === 'object' && arguments.length === 1) {
            _.extend(this.data, str);
        }

        Cookies.set(this.config.key, this.data, _.extend(this.config.options, options || {}));

        return this;
    }

    expire(daysUntilExpire) {
        if (daysUntilExpire) {
            Cookies.set(this.data, _.extend(this.config.options, { expires: daysUntilExpire }));
        } else {
            Cookies.remove(this.config.key);
            this.data = {};
        }
        return this;
    }

    options(obj) {
        if (!arguments.length) return this.config.options;

        this.config.options = typeof obj === 'object' ? obj : {};

        return this;
    }

    enabled() {
        return navigator.cookieEnabled;
    }
}
