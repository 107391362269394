<template>
    <div>
        <main-header></main-header>

        <div class="grid-cont content-cont">
            <div class="resource">
                <div class="resource__head">
                    <h1 class="h2 resource__title" v-html="originalStaticContent.title"></h1>
                </div>

                <div class="mt--250 static-cont" v-html="originalStaticContent.description"></div>
            </div>
        </div>

        <pathway-footer></pathway-footer>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import bookmarkActions from '../../../mixins/bookmarkActions';

export default {
    name: 'static-content-view',
    props: ['originalGuest', 'originalStaticContent', 'allStaticContent'],
    mixins: [collectorActions],
    mounted() {
        this.$store.commit('SET_GUEST', this.originalGuest);
        this.$store.commit('SET_STATIC_CONTENT', this.allStaticContent);
        this.openStaticContentAction(this.originalStaticContent);
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss">
.content-cont {
    margin-top: 80px;
    min-height: 100%;
    height: 100%;
}
.static-cont {
    text-align: justify;
    color: #23202e;

    ul {
        margin-top: 20px;
        margin-left: 20px;
    }
}
</style>
