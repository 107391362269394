require('./bootstrap');

import './helpers/filters.js';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import Vuex from 'vuex';
import VueYouTubeEmbed from 'vue-youtube-embed';
import store from './vuex/guestStore';
import VTooltip from 'v-tooltip';
import VueTour from 'vue-tour';
import VueShepherd from 'vue-shepherd';

require('vue-tour/dist/vue-tour.css');
require('shepherd.js/dist/css/shepherd.css');

window.Vue = require('vue');

Vue.use(Vuex);
Vue.use(VueYouTubeEmbed);
Vue.use(VTooltip);
Vue.use(VueTour);
Vue.use(VueShepherd);

const files = require.context('./', true, /\.vue$/i);
files.keys().map(key =>
    Vue.component(
        key
            .split('/')
            .pop()
            .split('.')[0],
        files(key).default
    )
);

const app = new Vue({
    el: '#app',
    store
});

if (APP_ENV == 'production') {
    Sentry.init({
        debug: true,
        dsn: SENTRY_LARAVEL_DSN,
        integrations: [
            new Integrations.Vue({ app, attachProps: true, logErrors: true, tracing: false })
        ]
    });

    if (window.authUser) {
        Sentry.configureScope(function(scope) {
            scope.setUser(window.authUser);
        });
    }
}
