<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div class="admin-header">
            <v-row>
                <div class="col-auto">
                    <back-button url="/admin/guests"></back-button>
                </div>
                <div class="display-1 col-auto">Actions: {{ guest.email || guest.uuid }}</div>
            </v-row>
        </div>

        <div class="admin-table">
            <v-data-table
                :headers="headers"
                :items="actions"
                :options.sync="options"
                :footer-props="{ 'items-per-page-options': [20, 50, 75, 100] }"
                :loading="loading"
                :server-items-length="totalActions"
                :items-per-page="20"
                class="elevation-1"
            >
                <template v-slot:no-data>
                    <div>No data</div>
                </template>
                <template v-if="actions.length" v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="item in items" :key="item.uuid">
                            <td>{{ item.session_uuid }}</td>
                            <td>
                                <v-row no-gutters align="center">
                                    <edit-button
                                        :url="
                                            `/admin/guest_actions/${item.session_uuid}/${item.guest_uuid}`
                                        "
                                        text="Details"
                                    ></edit-button>
                                </v-row>
                            </td>
                            <td>{{ renderLocalTime(item.created_at) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>
<script>
import Time from '../../../mixins/Time';

export default {
    props: ['authUser', 'guest'],
    mixins: [Time],
    data() {
        return {
            actions: [],
            loading: false,
            overlay: false,
            totalActions: 0,
            headers: [
                { text: 'Session UUID', value: 'session_uuid', sortable: true },
                { text: 'Actions', value: '', sortable: false },
                { text: 'Date', value: 'created_at', sortable: true }
            ],
            options: { sortDesc: [true], sortBy: ['created_at'] }
        };
    },
    methods: {
        getDataFromApi: _.debounce(function() {
            this.loading = true;
            this.overlay = true;

            let endpoint = '/admin/guests/' + this.guest.id + '/actions_json';
            let descendingQuery =
                this.options.sortDesc[0] !== undefined
                    ? '&descending=' + this.options.sortDesc[0]
                    : '';
            let queries =
                '?page=' +
                this.options.page +
                '&rowsPerPage=' +
                this.options.itemsPerPage +
                '&sortBy=' +
                this.options.sortBy +
                descendingQuery;

            axios
                .get(endpoint + queries)
                .then(response => {
                    this.actions = response.data.data;
                    this.totalActions = response.data.total;
                    this.loading = false;
                    this.overlay = false;
                })
                .catch(function(error) {
                    console.error(error);
                });
        }, 100)
    },
    watch: {
        options: {
            handler() {
                this.getDataFromApi();
            },
            deep: true
        }
    }
};
</script>
