<template>
    <div class="footer">
        <template v-for="(content, index) in staticContent">
            <a :href="`/aarp/${content.slug}`" class="footer__link">{{ content.title }}</a>
            <div v-if="index + 1 < staticContent.length" class="footer__divider"></div>
        </template>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    name: 'pathway-footer',
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            pathway: 'pathway',
            guest: 'guest',
            staticContent: 'staticContent'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss">
.footer {
    margin-top: 40px;
}
</style>
