<template>
    <div class="feedback-container">
        <div class="deepdive-thanks" v-if="showThanksMessage">
            <h4>Thank You for Your Feedback</h4>
            <p class="mt--125 p p--sm deepdive-thanks__subtitle">
                We will constantly trying to improve the lessons and overall experience. Thanks for
                providing&nbsp;feedback.
            </p>
            <a
                href="#"
                @click="redirectToFeed"
                dusk="returnToFeed"
                class="button button--wide button--tertiary deepdive-thanks__button"
                ><span>Return to Feed</span>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2.4"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <polyline points="13 6 19 12 13 18"></polyline>
                </svg>
            </a>
            <button type="button" class="deepdive-thanks__close">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </button>
        </div>
        <div class="deepdive-feedback" v-else>
            <div>
                <div class="deepdive-feedback__figure">
                    <img
                        src="/img/deepdive-feedback/image.png"
                        srcset="/img/deepdive-feedback/image@2x.png 2x"
                        alt=""
                        class="deepdive-feedback__image"
                    />
                </div>
                <div class="mt--050 h4">You’ve Completed the Lesson!</div>
                <div class="mt--075 p p--xs">
                    Great job, you’ve completed the lesson<br />
                    <em>“{{ pathway.title }}”</em><br />
                    Please rate your experience.
                </div>

                <div class="mt--125 deepdive-rating">
                    <div class="grid-row grid-row--smg grid-row--jcc">
                        <div
                            class="grid-col grid-col--auto"
                            v-for="feedbackOption in feedbackOptions"
                        >
                            <div class="deepdive-rating__item">
                                <input
                                    type="radio"
                                    name="deepdive-rating"
                                    class="deepdive-rating__input"
                                    @click="showFeedbackInput(feedbackOption.name)"
                                    :dusk="`feedbackOption-${feedbackOption.name}`"
                                />
                                <div class="deepdive-rating__figure">
                                    <img :src="feedbackOption.image" alt="" />
                                </div>
                                <div
                                    class="deepdive-rating__label"
                                    v-text="feedbackOption.label"
                                ></div>
                            </div>
                        </div>
                    </div>

                    <transition name="slide">
                        <div class="deepdive-rating__spoiler" v-if="feedbackInput">
                            <label class="p"
                                ><em class="tc--xdark">How can we improve future lessons?</em>
                                (optional)</label
                            >
                            <div class="mt--075 input">
                                <textarea
                                    rows="3"
                                    v-model="detailedFeedback"
                                    cols="7"
                                    placeholder="Add your ideas briefly..."
                                    class="input__input"
                                ></textarea>
                            </div>
                        </div>
                    </transition>
                </div>

                <div class="deepdive-feedback__foot">
                    <div class="grid-row grid-row--nog grid-row--aic grid-row--jcb">
                        <div class="grid-col grid-col--auto">
                            <button
                                type="button"
                                class="deepdive-feedback__skip"
                                @click="skipAndExit()"
                            >
                                Skip &amp; Exit
                            </button>
                        </div>
                        <div class="grid-col grid-col--auto">
                            <button
                                type="button"
                                :disabled="!feedbackInput"
                                @click="storeFeedback"
                                dusk="submitFeedback"
                                class="button button--tertiary deepdive-feedback__button"
                            >
                                <span>Rate Lesson</span>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2.4"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                    <polyline points="13 6 19 12 13 18"></polyline>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'pathway-feedback',
    data() {
        return {
            showThanksMessage: false,
            rating: null,
            feedbackInput: false,
            detailedFeedback: null,
            selectedFeedback: null,
            feedbackOptions: [
                { image: '/img/deepdive-rating/poor.svg', label: 'Poor', name: 'poor' },
                { image: '/img/deepdive-rating/okay.svg', label: 'Okay', name: 'okay' },
                { image: '/img/deepdive-rating/good.svg', label: 'Good', name: 'good' },
                { image: '/img/deepdive-rating/awesome.svg', label: 'Awesome', name: 'awesome' }
            ]
        };
    },
    computed: {
        ...mapGetters({
            pathway: 'pathway',
            guest: 'guest'
        })
    },
    methods: {
        skipAndExit() {
            window.location.replace('/');
        },
        showFeedbackInput(chosenRating) {
            this.feedbackInput = true;
            this.rating = chosenRating;
        },
        storeFeedback() {
            axios
                .post('/guest/api/pathway_feedback/' + this.pathway.id, {
                    rating: this.rating,
                    description: this.detailedFeedback
                })
                .catch(response => {
                    console.log(response);
                })
                .then(response => {
                    this.showThanksMessage = true;
                });
        },
        redirectToFeed() {
            window.location.replace('/');
        }
    }
};
</script>
<style lang="scss" scoped>
.feedback-container {
    z-index: 999;
    width: 450px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.slide-enter-active {
    -moz-transition-duration: 0.1s;
    -webkit-transition-duration: 0.1s;
    -o-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -moz-transition-timing-function: ease;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
}

.slide-enter-to,
.slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>
