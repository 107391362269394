export default {
    methods: {
        openPathwayAction(pathway_id) {
            dataCollector.addAction('open_pathway', {
                pathway_id: pathway_id
            });
        },
        openIntroPathwayAction(pathway_id) {
            dataCollector.addAction('open_intro_pathway', {
                pathway_id: pathway_id
            });
        },
        openLoginFormAction(pathway_id) {
            dataCollector.addAction('open_intro_login_form', {
                pathway_id: pathway_id
            });
        },
        backToIntroAction(pathway_id) {
            dataCollector.addAction('back_to_home', {
                pathway_id: pathway_id
            });
        },
        skipContentAction(content_id, node_id, pathway_id) {
            dataCollector.addAction('skip_content', {
                content_id: content_id,
                node_id: node_id,
                pathway_id: pathway_id
            });
        },
        openContentAction(content_id) {
            dataCollector.addAction('open_content', {
                content_id: content_id
            });
        },
        introLoginAction(email, message) {
            dataCollector.addAction('intro_login', {
                email: email,
                message: message
            });
        },
        openStaticContentAction(content) {
            dataCollector.addAction('open_static_content', {
                id: content.id,
                title: content.title
            });
        },
        loadNodeAction(node) {
            let data = {
                pathway_id: node.pathway_id,
                content_id: node.content_id,
                node_id: node.id
            };

            setTimeout(() => {
                dataCollector.addAction('load_node', data);
            }, 500);
        },
        goBackAction(node) {
            let data = {
                pathway_id: node.pathway_id,
                content_id: node.content_id,
                node_id: node.id
            };

            setTimeout(() => {
                dataCollector.addAction('go_back_node', data);
            }, 500);
        },
        openFeedAction() {
            dataCollector.addAction('open_feed');
        },
        answerQuestionAction(content, question, answer, pathway_id) {
            dataCollector.addAction('answer_question', {
                content_id: content.id,
                question_id: question.id,
                answer_id: answer.id,
                question_text: question.text,
                answer_text: answer.text,
                pathway_id: pathway_id
            });
        },
        bookmarkContentAction(content, pathway_id) {
            dataCollector.addAction('bookmark_content', {
                content_id: content.id,
                pathway_id: pathway_id
            });
        },
        discardBookmarkAction(content, pathway_id) {
            dataCollector.addAction('discard_content_bookmark', {
                content_id: content.id,
                pathway_id: pathway_id
            });
        },
        discardFeedContentAction(content) {
            dataCollector.addAction('discard_feed_content', {
                content_id: content.id
            });
        },
        submitEmailAction(email, pathway_id) {
            dataCollector.addAction('submit_email', {
                email: email,
                pathway_id: pathway_id
            });
        },
        playVideoAction(content, pathway_id) {
            dataCollector.addAction('play_video', {
                content_id: content.id,
                pathway_id: pathway_id
            });
        },
        exitPathwayAction(pathway_id, node_id) {
            dataCollector.addAction('exit_pathway', {
                pathway_id: pathway_id,
                node_id: node_id
            });
        },
        openProfileAction() {
            dataCollector.addAction('open_profile');
        },
        openActivityAction() {
            dataCollector.addAction('open_activity');
        },
        searchActivityAction(tab, searchText, results) {
            dataCollector.addAction('search_activity', {
                tab: tab,
                search_text: searchText,
                search_results: results
            });
        },
        updateProfileAction(guest) {
            dataCollector.addAction('update_profile', {
                name: guest.name,
                email: guest.email
            });
        },
        searchAction(searchText, results) {
            dataCollector.addAction('search_site', {
                search_text: searchText,
                search_results: results
            });
        },
        openSearchAction(searchText, results, page) {
            dataCollector.addAction('open_search_page', {
                search_text: searchText,
                pagination_page: page,
                search_results: results
            });
        }
    }
};
