<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar color="success" top v-model="snackbar" :timeout="1500">
            Content updated!
            <v-icon dark>check_circle</v-icon>
        </v-snackbar>

        <div class="admin-header">
            <v-row>
                <div class="col-auto">
                    <back-button url="/admin/static_content"></back-button>
                </div>

                <div class="display-1 col-auto">
                    {{ content ? 'Edit ' + form.title : 'Create Static content' }}
                </div>
                <v-spacer></v-spacer>
                <div class="grid-col grid-col--auto d-flex align-center">
                    <save-button @click="submit"></save-button>
                </div>
            </v-row>
        </div>

        <v-form ref="form" v-model="valid">
            <v-row>
                <v-col cols="12" md="12" sm="12" xs="12">
                    <v-text-field
                        required
                        label="Title"
                        placeholder="Enter the title"
                        v-model="form.title"
                        :rules="[v => !!v || 'Please enter a title']"
                        :error-messages="form.errors.first('title')"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col md="12">
                    <tiptap-editor
                        v-bind="options"
                        v-model="form.description"
                        placeholder=""
                        file-selector-url="/admin/static_images/"
                        file-upload-url="/admin/static_images/"
                    ></tiptap-editor>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="auto">
                    <save-button @click="submit"></save-button>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import Time from '../../../mixins/Time';
import StaticContentForm from '../../../classes/Form/StaticContentForm';
import { TiptapEditor } from 'tiptap-editor/src';
import PathwayForm from '../../../classes/Form/PathwayForm';
import BackButton from '../buttons/BackButton';
import SaveButton from '../buttons/SaveButton';

export default {
    name: 'static-content-c-u',
    props: ['content'],
    components: {
        SaveButton,
        BackButton,
        TiptapEditor
    },
    mixins: [Time],
    created() {
        if (this.content) {
            this.form = new StaticContentForm(this.content);
        }
    },
    data() {
        return {
            form: new StaticContentForm(),
            snackbar: false,
            overlay: false,
            valid: false,
            options: {
                editable: true
            }
        };
    },
    methods: {
        submit() {
            let valid = this.$refs.form.validate();

            if (valid) {
                this.overlay = true;
                if (this.content) {
                    this.form
                        .put('/admin/static_content/' + this.form.id)
                        .then(response => {
                            this.snackbar = true;
                            this.overlay = false;

                            this.form = new StaticContentForm(response.content);
                        })
                        .catch(e => {
                            this.overlay = false;
                        });
                } else {
                    this.form
                        .post('/admin/static_content')
                        .then(response => {
                            if (response.content.id) {
                                window.location =
                                    '/admin/static_content/' + response.content.id + '/edit';
                            } else {
                                alert('something went wrong');
                                this.overlay = false;
                            }
                        })
                        .catch(e => {
                            this.overlay = false;
                        });
                }
            }
        }
    },
    watch: {}
};
</script>
