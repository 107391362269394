<template>
    <div class="grid-col grid-col--4 grid-col--md-10 grid-col--sm-12">
        <a href="/profile" class="rfeed-user">
            <div class="grid-row grid-row--smg grid-row--aic">
                <div class="grid-col grid-col--auto">
                    <div class="rfeed-user__avatar">A</div>
                </div>
                <div class="grid-col grid-col--auto">
                    <div class="rfeed-user__name">{{ guest.name }}</div>
                    <div class="rfeed-user__email">{{ guest.email }}</div>
                </div>
                <div class="grid-col grid-col--auto">
                    <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="rfeed-user__arrow"
                    >
                        <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                </div>
            </div>
        </a>

        <div class="rfeed-menu">
            <a href="/activity" class="rfeed-menu__item">
                <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    class="rfeed-menu__icon"
                >
                    <path
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21.875 24.5L13.9992 20.125L6.125 24.5V4.375C6.125 4.14294 6.21719 3.92038 6.38128 3.75628C6.54538 3.59219 6.76794 3.5 7 3.5H21C21.2321 3.5 21.4546 3.59219 21.6187 3.75628C21.7828 3.92038 21.875 4.14294 21.875 4.375V24.5Z"
                    />
                </svg>
                <div class="rfeed-menu__label">
                    {{ guest && guest.resource_ids ? guest.resource_ids.length : 0 }} Saved
                    Resources
                </div>
                <svg
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="rfeed-menu__arrow"
                >
                    <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
            </a>
            <a href="/activity#Viewed" class="rfeed-menu__item">
                <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    class="rfeed-menu__icon"
                >
                    <path
                        d="M18.375 2.625H9.625V7H18.375V2.625Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M18.375 4.375H21.875C22.1071 4.375 22.3296 4.46719 22.4937 4.63128C22.6578 4.79538 22.75 5.01794 22.75 5.25V23.625C22.75 23.8571 22.6578 24.0796 22.4937 24.2437C22.3296 24.4078 22.1071 24.5 21.875 24.5H6.125C5.89294 24.5 5.67038 24.4078 5.50628 24.2437C5.34219 24.0796 5.25 23.8571 5.25 23.625V5.25C5.25 5.01794 5.34219 4.79538 5.50628 4.63128C5.67038 4.46719 5.89294 4.375 6.125 4.375H9.625"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M10.5 16.625H17.5"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                    />
                    <path
                        d="M10.5 13.125H17.5"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                    />
                </svg>
                <div class="rfeed-menu__label">{{ guest.content_count }} Resources Viewed</div>
                <svg
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="rfeed-menu__arrow"
                >
                    <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
            </a>
        </div>

        <feed-pathways></feed-pathways>

        <feed-footer></feed-footer>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'feed-sidebar',
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss">
.rfeed-user {
    padding: 15px;
}
</style>
