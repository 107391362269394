<template>
    <div class="rfeed">
        <main-header></main-header>

        <div class="grid-cont rfeed__cont">
            <transition name="fade" mode="out-in">
                <notification
                    class="mb--200"
                    :message="flashMessage"
                    :duration="15000"
                    @close="snackbar = false"
                    v-if="snackbar"
                ></notification>
            </transition>
        </div>
        <div class="grid-cont rfeed__cont">
            <div class="grid-row grid-row--jcc">
                <feed-timeline
                    :featured-pathway="featuredPathway"
                    :featured-content="featuredContent"
                    :saved-content="savedContent"
                ></feed-timeline>
                <feed-sidebar></feed-sidebar>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    props: [
        'originalGuest',
        'staticContent',
        'featuredPathway',
        'content',
        'featuredContent',
        'flashMessage',
        'savedContent'
    ],
    mixins: [collectorActions],
    data() {
        return {
            snackbar: false
        };
    },
    mounted() {
        this.$store.commit('SET_GUEST', this.originalGuest);
        this.$store.commit('SET_STATIC_CONTENT', this.staticContent);
        this.$store.commit('SET_FEED_CONTENT', this.content);
        this.openFeedAction();

        if (this.flashMessage) {
            this.snackbar = true;
        }
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss"></style>
