<template>
    <div class="onboard-questionnaire">
        <div
            class="onboard-questionnaire__item"
            :class="{ active: currentIndex === index, passed: currentIndex > index }"
            v-for="(question, index) in content.questions"
        >
            <div class="grid-row grid-row--nog grid-row--aic onboard-questionnaire__row">
                <div class="grid-col grid-col--6 grid-col--md-12">
                    <div class="onboard-questionnaire__overline">
                        Question {{ index + 1 }} of {{ content.questions.length }}
                    </div>
                    <h4 class="onboard-questionnaire__heading">{{ question.text }}</h4>
                </div>
                <div class="grid-col grid-col--6 grid-col--md-12">
                    <div class="onboard-questionnaire__answers">
                        <template
                            v-for="(answer, index) in question.answers"
                            @keypress="selectAnswer($event)"
                        >
                            <a
                                @click.prevent="answerChosen(answer, question)"
                                :dusk="`answer-${index}`"
                                class="onboard-questionnaire__answer"
                            >
                                {{ answer.text }}
                                <span>{{ index + 1 }}</span>
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    props: ['revertChoice'],
    mixins: [collectorActions],
    data() {
        return {
            currentIndex: 0
        };
    },
    created() {
        window.addEventListener('keypress', this.selectAnswer);
    },
    destroyed() {
        window.removeEventListener('keypress', this.selectAnswer);
    },
    methods: {
        answerChosen(answer, question, index) {
            axios
                .post('/guest/api/answer_choices/' + question.id, {
                    answer_id: answer.id,
                    node_id: this.node.id,
                    content_id: this.content.id
                })
                .then(response => {
                    this.$store.commit('SET_LATEST_ANSWER', response.data.latestAnswer);

                    this.answerQuestionAction(this.content, question, answer, this.pathway.id);

                    this.currentIndex++;
                })
                .catch(response => {
                    alert('There was an issue storing your answer');
                });
        },
        selectAnswer(event) {
            let index = event.key - 1;
            let question = this.content.questions[this.currentIndex];
            let answer = question.answers[index];

            if ([49, 50, 51, 52].includes(event.charCode)) {
                this.answerChosen(answer, question, index);
            }
        },
        deleteAnswer() {
            axios
                .delete('/guest/api/answer_choices/' + this.latestAnswer.id)
                .then(response => {
                    this.currentIndex--;

                    this.$store.commit('SET_LATEST_ANSWER', response.data.latestAnswer);
                })
                .catch(response => {
                    alert('There was an issue reverting your answer');
                });
        },
        latestAnswerIndexCheck() {
            let index = this.content.questions.findIndex(
                x => x.id === this.latestAnswer.question_id
            );

            return index + 1 > this.content.questions.length - 1;
        }
    },
    computed: {
        ...mapGetters({
            pathway: 'pathway',
            guest: 'guest',
            node: 'node',
            content: 'content',
            latestAnswer: 'latestAnswer'
        }),
        currentQuestionIsLast() {
            return this.currentIndex + 1 >= this.content.questions.length;
        }
    },
    watch: {
        'latestAnswer.id': {
            handler() {
                if (this.latestAnswer && this.latestAnswerIndexCheck()) {
                    this.$emit('loadNext');
                }
            },
            deep: false,
            immediate: true
        },
        revertChoice: {
            handler() {
                if (this.revertChoice && this.currentIndex > 0) {
                    this.deleteAnswer();
                    this.$emit('updateRevertChoice', false);
                } else if (this.revertChoice && this.currentIndex === 0) {
                    this.$emit('updateRevertChoice', false);
                    this.$emit('backStep');
                }
            },
            immediate: true
        }
    }
};
</script>
<style lang="scss">
.onboard-questions__item {
    opacity: 0;
    &.active {
        opacity: 1;
    }
}
.onboard-questions__answer {
    display: flex;
    justify-content: space-between;
    span {
        display: table;
    }
}
</style>

<style lang="scss">
//.onboard-questions__item {
//    opacity: 0;
//
//    &.active {
//        opacity: 1;
//    }
//}

.onboard-questions__answer {
    display: flex;
    justify-content: space-between;

    span {
        display: table;
    }
}
</style>
