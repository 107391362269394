<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div class="admin-header">
            <v-row>
                <div class="display-1 col-auto">Content</div>
                <v-spacer></v-spacer>
                <div class="col-sm-2" v-if="authUser.admin">
                    <create-button
                        url="/admin/content/create"
                        text="Create new content"
                    ></create-button>
                </div>
            </v-row>

            <v-row>
                <v-col class="col-auto">
                    <v-menu
                        bottom
                        offset-y
                        :close-on-content-click="false"
                        class="filter-menu"
                        max-width="250px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-badge
                                :content="searchTotal"
                                :value="searchTotal"
                                color="red"
                                left
                                overlap
                            >
                                <v-btn text outlined v-bind="attrs" v-on="on">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        stroke="#62727B"
                                        stroke-width="2.4"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <line x1="3" y1="7" x2="21" y2="7"></line>
                                        <line x1="6" y1="12" x2="18" y2="12"></line>
                                        <line x1="9" y1="17" x2="15" y2="17"></line>
                                    </svg>
                                </v-btn>
                            </v-badge>
                        </template>
                        <v-list>
                            <v-list-item class="filter-menu">
                                <v-list-item-content>
                                    <v-list-item-title class="filter-title">Type</v-list-item-title>
                                    <v-list-item-subtitle v-for="type in types" :key="type">
                                        <v-checkbox
                                            hide-details
                                            dense
                                            multiple
                                            :value="type"
                                            v-model="search.types"
                                            :label="type"
                                        ></v-checkbox>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="filter-menu">
                                <v-list-item-content>
                                    <v-list-item-title class="filter-title"
                                        >Topics</v-list-item-title
                                    >
                                    <v-list-item-subtitle>
                                        <v-autocomplete
                                            label="Search by topics"
                                            v-model="search.topics"
                                            :items="allTopics"
                                            chips
                                            clearable
                                            deletable-chips
                                            multiple
                                            small-chips
                                            item-text="name"
                                            item-value="id"
                                        ></v-autocomplete>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>

                <v-col class="col-auto">
                    <v-menu
                        bottom
                        offset-y
                        :close-on-content-click="false"
                        class="filter-menu"
                        max-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text outlined v-bind="attrs" v-on="on">
                                <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 19"
                                    fill="#62727B"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.81611 5.90989H12.7269V3.74443H1.81611C1.21817 3.74443 0.733398 4.2292 0.733398 4.82714C0.733398 5.42508 1.21817 5.90989 1.81611 5.90989Z"
                                    ></path>
                                    <path
                                        d="M1.81611 10.255H12.7269V8.08954H1.81611C1.21817 8.08954 0.733398 8.5743 0.733398 9.17227C0.733398 9.77025 1.21817 10.255 1.81611 10.255Z"
                                    ></path>
                                    <path
                                        d="M1.81611 12.4345C1.21817 12.4345 0.733398 12.9193 0.733398 13.5173C0.733398 14.1153 1.21817 14.6 1.81611 14.6H9.39614C9.31051 14.3363 9.26531 14.0581 9.26531 13.7728C9.26531 13.2951 9.39059 12.8368 9.62459 12.4345H1.81611Z"
                                    ></path>
                                    <path
                                        d="M19.8249 12.8048C19.2903 12.27 18.4233 12.27 17.8886 12.8048L16.7643 13.929V2.22614C16.7643 1.46991 16.1513 0.856934 15.3951 0.856934C14.6389 0.856934 14.0259 1.46995 14.0259 2.22614V13.929L12.9016 12.8048C12.3669 12.2701 11.5 12.27 10.9653 12.8048C10.4305 13.3395 10.4305 14.2064 10.9653 14.7411L14.4267 18.2025C14.9604 18.7364 15.8284 18.7382 16.3635 18.2025L19.8249 14.7411C20.3596 14.2064 20.3597 13.3395 19.8249 12.8048Z"
                                    ></path>
                                </svg>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item class="filter-menu">
                                <v-list-item-content>
                                    <v-list-item-title class="filter-title"
                                        >Sort by</v-list-item-title
                                    >
                                    <v-list-item-subtitle>
                                        <v-radio-group v-model="sortOptions.sortBy">
                                            <v-radio
                                                hide-details
                                                dense
                                                value="title"
                                                label="Title"
                                            ></v-radio>
                                            <v-radio
                                                hide-details
                                                dense
                                                value="created_at"
                                                label="Date added"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item class="filter-menu">
                                <v-list-item-content>
                                    <v-list-item-title class="filter-title"
                                        >Order</v-list-item-title
                                    >
                                    <v-list-item-subtitle>
                                        <v-radio-group v-model="sortOptions.desc">
                                            <v-radio
                                                hide-details
                                                dense
                                                :value="false"
                                                label="Ascending"
                                            ></v-radio>
                                            <v-radio
                                                hide-details
                                                dense
                                                :value="true"
                                                label="Descending"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>

                <v-col class="col-4" style="max-height: 60px">
                    <v-text-field
                        outlined
                        solo
                        dense
                        append-icon="mdi-magnify"
                        label="Search by title or description"
                        v-model="search.title"
                        @input="search.title.length > 3 ? getDataFromApi() : ''"
                    ></v-text-field>
                </v-col>
                <v-col cols="auto" v-if="searchIsActive">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="error" depressed @click="resetSearch" v-on="on">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    enable-background="new 0 0 24 24"
                                    viewBox="0 0 24 24"
                                    fill="white"
                                    width="18px"
                                    height="18px"
                                >
                                    <g>
                                        <path d="M0,0h24v24H0V0z" fill="none" />
                                    </g>
                                    <g>
                                        <g>
                                            <path
                                                d="M12,5V2L8,6l4,4V7c3.31,0,6,2.69,6,6c0,2.97-2.17,5.43-5,5.91v2.02c3.95-0.49,7-3.85,7-7.93C20,8.58,16.42,5,12,5z"
                                            />
                                            <path
                                                d="M6,13c0-1.65,0.67-3.15,1.76-4.24L6.34,7.34C4.9,8.79,4,10.79,4,13c0,4.08,3.05,7.44,7,7.93v-2.02 C8.17,18.43,6,15.97,6,13z"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </v-btn>
                        </template>
                        <span>Reset search</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <v-spacer></v-spacer>

        <br />

        <template v-for="content in contentList">
            <admin-content-card
                :content="content"
                class="mb-2"
                :key="content.id"
                @applySearch="applySearch($event)"
            ></admin-content-card>
        </template>

        <div class="text-center my-5">
            <v-pagination v-model="page" :length="this.lastPage" :total-visible="7"></v-pagination>
        </div>
    </div>
</template>
<script>
import Time from '../../../mixins/Time';
import ContentEditorModal from './ContentEditorModal';
import { mapGetters } from 'vuex';
import AdminContentCard from './AdminContentCard';
import CreateButton from '../buttons/CreateButton';

export default {
    name: 'content-list',
    props: ['authUser', 'types', 'allTopics'],
    components: { CreateButton, AdminContentCard, ContentEditorModal },
    mixins: [Time],
    created() {
        this.getDataFromApi();
    },
    data() {
        return {
            settingsSearch: false,
            search: {
                title: '',
                topics: [],
                types: [],
                settings: {}
            },
            sortOptions: {
                sortBy: 'created_at',
                desc: true
            },
            total: 0,
            modalProperties: {},
            modal: false,
            loading: false,
            page: 1,
            itemsPerPage: 20,
            lastPage: 0,
            overlay: false
        };
    },
    computed: {
        ...mapGetters({
            contentList: 'contentList'
        }),
        searchIsActive() {
            return !!(this.search.title || this.search.topics.length || this.search.types.length);
        },
        searchTotal() {
            let total = 0;
            total += this.search.types.length;
            total += this.search.topics.length;
            return total;
        }
    },
    methods: {
        isEmpty(item) {
            return _.isEmpty(item);
        },
        resetSearch() {
            this.search = {
                title: '',
                topics: [],
                types: [],
                settings: {}
            };

            this.sortOptions.desc = true;

            this.getDataFromApi();
        },
        applySearch(data) {
            let key = Object.keys(data)[0];
            if (this.search[key] instanceof Array) {
                this.search[key].push(data[key]);

                this.search[key] = _.uniq(this.search[key]);
            } else {
                this.search[key] = data[key];
            }
            this.getDataFromApi();
        },
        getDataFromApi: _.debounce(function() {
            this.loading = true;
            this.overlay = true;

            let endpoint = '/admin/content/json';
            let descendingQuery = '&descending=' + this.sortOptions.desc;
            let queries =
                '?page=' +
                this.page +
                '&rowsPerPage=' +
                this.itemsPerPage +
                '&sortBy=' +
                this.sortOptions.sortBy +
                descendingQuery;

            for (let property in this.search) {
                if (typeof this.search[property] !== 'object') {
                    queries += '&' + property + '=' + this.search[property];
                } else if (
                    typeof this.search[property] === 'object' &&
                    !_.isEmpty(this.search[property])
                ) {
                    for (let prop in this.search[property]) {
                        queries +=
                            '&' + property + '[' + prop + ']' + '=' + this.search[property][prop];
                    }
                }
            }

            axios
                .get(endpoint + queries)
                .then(response => {
                    this.$store.commit('SET_CONTENT_LIST', response.data.data);
                    this.total = response.data.total;
                    this.loading = false;
                    this.lastPage = response.data.last_page;
                    this.overlay = false;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .catch(function(error) {
                    console.error(error);
                });
        }, 400)
    },
    watch: {
        search: {
            handler() {
                this.getDataFromApi();
            },
            deep: true
        },
        page: {
            handler() {
                this.getDataFromApi();
            }
        },
        sortOptions: {
            handler() {
                this.getDataFromApi();
            },
            deep: true
        }
    }
};
</script>

<style lang="scss"></style>
