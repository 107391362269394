export default {
    methods: {
        getTourStepsForFeed(tour, featuredLesson, featuredContent) {
            let steps = [
                {
                    attachTo: { element: "#feedContainer", on: 'right-start' },
                    scrollTo: false,
                    classes: 'feed-step',
                    title: '<div class="p p--xs tutorial__num">TIP 1 of 5</div><h4>Your Feed</h4>',
                    text: '<p class="mt--050 step-description">'+'This is where you can find AARP resources curated to your interests and needs. As you watch videos, read articles, and interact with other content, we\'ll continue to tailor it based on what\'s important to you.'+'</p>',
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Next',
                            action: tour.next,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '#feedContainer',
                                },
                            },
                        ]
                    }
                },
            ];

            if (featuredLesson) {
                steps.push({
                    attachTo: { element: ".rfeed-lesson", on: 'right' },
                    title: '<div class="p p--xs tutorial__num">TIP 2 of 5</div><h4>Personalized Deep-Dive lessons on important topics</h4>',
                    text: '<p class="mt--050 step-description">Take a look at these recommended lessons.</p>',
                    scrollTo: false,
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Next',
                            action: tour.next,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.rfeed-lesson',
                                },
                            },
                        ]
                    }
                });
            }

            if (featuredContent) {
                steps.push({
                    attachTo: { element: ".rfeed-featured", on: 'right' },
                    title: '<div class="p p--xs tutorial__num">TIP 3 of 5</div><h4>Featured Resources</h4>',
                    text: '<p class="mt--050 step-description">Lorem ipsum..</p>',
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Next',
                            action: tour.next,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.rfeed-featured',
                                },
                            },
                        ]
                    }
                });
            }

            steps.push({
                attachTo: { element: ".rfeed-article__action--book", on: 'right' },
                title: '<div class="p p--xs tutorial__num">TIP 4 of 5</div><h4>Save your favorite articles</h4>',
                text: '<p class="mt--050 step-description">Lorem ipsum..</p>',
                buttons: [
                    {
                        text: 'End Tutorial',
                        action: tour.complete,
                        classes: 'p tutorial__end end-tour-button',
                    },
                    {
                        text: 'Next',
                        action: tour.next,
                        classes: 'button button--sm button--tertiary next-button'
                    },
                ],
                popperOptions: {
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                fallbackPlacements: ['bottom'],
                                rootBoundary: '.rfeed-article__action--book',
                            },
                        },
                    ]
                }
            });

            return steps;
        },
        getTourStepsForPathwayView(tour) {
          return [
              {
                  attachTo: { element: "#notepad__toggle", on: 'left' },
                  scrollTo: false,
                  title: '<div class="p p--xs tutorial__num">TIP 5 of 5</div><h4>Take Notes</h4>',
                  text: '<p class="mt--050 step-description">'+'Take some notes lorem ipsum'+'</p>',
                  buttons: [
                      {
                          text: 'End Tutorial',
                          action: tour.complete,
                          classes: 'p tutorial__end end-tour-button',
                      },
                      {
                          text: 'Done',
                          action: tour.next,
                          classes: 'button button--sm button--tertiary next-button'
                      },
                  ],
                  popperOptions: {
                      modifiers: [
                          {
                              name: 'flip',
                              options: {
                                  fallbackPlacements: ['bottom'],
                                  rootBoundary: '#notepad__toggle',
                              },
                          },
                      ]
                  }
              },
          ];
        },
        tourIsCompleteFor(key, guestId) {
            let storageKey = this.localStoragePath(key, guestId);

            console.log(storageKey, localStorage.getItem(storageKey));

            return !!localStorage.getItem(storageKey);
        },
        completeTourForGuest(key, guestId) {
            let storageKey = this.localStoragePath(key, guestId);

            localStorage.setItem(storageKey, Date.now());
        },
        localStoragePath(key, guestId) {
            return 'tour#' + guestId + '#' + key;
        }
    }
};
