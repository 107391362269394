import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        pathway: {},
        content: {},
        guest: {},
        node: {},
        lastNodeInPathway: false,
        staticContent: [],
        latestAnswer: false,
        feedContent: [],
        backAllowed: false,
        backToFeed: false
    },
    mutations: {
        SET_PATHWAY(state, pathway) {
            state.pathway = pathway;
        },
        SET_CONTENT(state, content) {
            state.content = content;
        },
        SET_GUEST(state, guest) {
            state.guest = guest;
        },
        SET_NODE(state, node) {
            state.node = node;
        },
        SET_STATIC_CONTENT(state, staticContent) {
            state.staticContent = staticContent;
        },
        SET_LATEST_ANSWER(state, latestAnswer) {
            state.latestAnswer = latestAnswer;
        },
        SET_FEED_CONTENT(state, content) {
            state.feedContent = content;
        },
        SET_BACK_ALLOWED(state, value) {
            state.backAllowed = value;
        },
        SET_BACK_TO_FEED(state, value) {
            state.backToFeed = value;
        }
    },
    getters: {
        pathway: state => state.pathway,
        content: state => state.content,
        guest: state => state.guest,
        node: state => state.node,
        lastNodeInPathway: state => state.lastNodeInPathway,
        staticContent: state => state.staticContent,
        latestAnswer: state => state.latestAnswer,
        feedContent: state => state.feedContent,
        backAllowed: state => state.backAllowed,
        backToFeed: state => state.backToFeed
    },
    actions: {
        storeAndSetNode({ state, commit }, node) {
            commit('SET_NODE', node);

            axios.post('/guest/api/nodes/guest_content', {
                guest_id: state.guest.id,
                content_id: node.content.id,
                pathway_id: node.pathway_id,
                node_id: node.id
            });
        },
        submitEmail({ state, commit }, email) {
            return axios.post('/guest/api/email', { email: email }).then(res => {
                commit('SET_GUEST', res.data.guest);

                return res;
            });
        },
        loadNextNode({ state, dispatch }) {
            return axios.post('/guest/api/nodes/load_next/' + state.node.id).then(res => {
                if (res.data.node !== false) {
                    dispatch('storeAndSetNode', res.data.node);
                } else {
                    state.lastNodeInPathway = true;
                }
            });
        },
        loadPreviousNode({ state, dispatch, commit }) {
            return axios.delete('/guest/api/nodes/load_previous/' + state.node.id).then(res => {
                dispatch('storeAndSetNode', res.data.node);
                commit('SET_LATEST_ANSWER', res.data.latestAnswer);
            });
        },
        bookmarkContent({ state, commit }, content) {
            axios
                .post('/guest/api/nodes/bookmark_content', {
                    guest_id: state.guest.id,
                    content_id: content.id
                })
                .then(res => {
                    commit('SET_GUEST', res.data.guest);
                });
        },
        discardContent({ state, commit }, content) {
            axios
                .post('/guest/api/content/discard', {
                    guest_id: state.guest.id,
                    content_id: content.id
                })
                .then(res => {
                    commit('SET_GUEST', res.data.guest);
                    let index = state.feedContent.findIndex(x => x.id === content.id);

                    if (index > -1) {
                        state.feedContent.splice(index, 1);
                    }
                });
        },
        closeFeedWelcome({ commit, state }) {
            axios.put('/guest/api/update', { welcome_message_viewed: true }).then(res => {
                commit('SET_GUEST', res.data.guest);
            });
        },
        removeContentBookmark({ state, commit }, content) {
            axios.delete('/guest/api/nodes/remove_bookmark/' + content.id).then(res => {
                commit('SET_GUEST', res.data.guest);
            });
        }
    }
});
