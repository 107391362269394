import Form from '../Form';

class StaticContentForm extends Form {
    constructor(data) {
        if (!data) {
            data = {
                title: '',
                slug: '',
                description: ''
            };
        }

        super(data);
    }
}

export default StaticContentForm;
