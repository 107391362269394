<template>
    <div class="onboard__slide" :class="{ current: isCurrent }">
        <div class="onboard-intro">
            <div class="onboard-intro__head">
                <img
                    src="/img/logo.png"
                    srcset="/img/logo@2x.png"
                    width="72"
                    height="16"
                    alt="AARP"
                />
            </div>

            <div class="grid-cont onboard-intro__cont">
                <div class="grid-row grid-row--aic grid-row--jcb">
                    <div class="grid-col grid-col--auto grid-col--md-12 intro-text">
                        <h1 class="h2 onboard-intro__title" v-html="pathway.name_html"></h1>
                        <p
                            class="p p--xxl onboard-intro__subtitle"
                            v-html="pathway.description_html"
                        ></p>

                        <button
                            type="button"
                            id="onboard-intro__button"
                            dusk="introButton"
                            class="button button--rounded button--primary onboard-intro__button"
                            @click="showPathway"
                        >
                            <span>Personalize your feed</span
                            ><svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2.4"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <line x1="12" y1="4" x2="12" y2="20"></line>
                                <polyline points="18 14 12 20 6 14"></polyline>
                            </svg>
                        </button>

                        <p class="p p--sm mt--100">
                            Already a member?
                            <a
                                @click.prevent="showLoginForm"
                                class="text-link pointer"
                                dusk="introLoginButton"
                                >Log in</a
                            >
                        </p>
                    </div>

                    <div class="grid-col grid-col--auto removed--md">
                        <div class="onboard-intro__cards"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import collectorActions from '../../../mixins/collectorActions';
import { mapGetters } from 'vuex';

export default {
    name: 'pathway-intro-slide',
    props: ['isCurrent'],
    mixins: [collectorActions],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            pathway: 'pathway'
        })
    },
    methods: {
        showLoginForm() {
            this.openLoginFormAction(this.pathway.id);
            this.$emit('showLogin');
        },
        showPathway() {
            this.$emit('showPathway');
        }
    },
    watch: {}
};
</script>

<style lang="scss">
.text-link {
    &.pointer {
        cursor: pointer;
    }
}

.intro-text {
    max-width: 50%;
}

@media screen and (max-width: 1279.98px) {
    .intro-text {
        max-width: 50%;
    }
}
@media screen and (max-width: 1023.98px) {
    .intro-text {
        max-width: 100%;
    }
}

@media screen and (max-width: 767.98px) {
    .intro-text {
        max-width: 100%;
    }
}

@media screen and (max-width: 599.98px) {
    .intro-text {
        max-width: 100%;
    }
}
</style>
