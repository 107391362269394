<template>
    <div class="rfeed-pers-lessons">
        <template v-if="guestPathways.length">
            <div class="rfeed-pers-lessons__head">
                <div class="rfeed-pers-lessons__title">Personalized Lessons</div>
                <button type="button" class="rfeed-pers-lessons__toggle" @click="togglePathways">
                    {{ showPathways ? 'Hide' : 'Show' }}
                </button>
            </div>

            <div
                class="rfeed-pers-lessons__item"
                :class="{ hidden: !showPathways }"
                v-for="pathway in guestPathways"
            >
                <div class="rfeed-pers-lessons__category">{{ pathway.name }}</div>
                <div class="rfeed-pers-lessons__name">{{ pathway.short_description }}</div>

                <a :href="`/pathway/${pathway.slug}`" class="rfeed-pers-lessons__resume"
                    >Resume Personalized Lesson</a
                >
            </div>
        </template>

        <template v-if="completedPathways.length">
            <div class="rfeed-pers-lessons__divider" v-if="showPathways"></div>

            <div
                class="rfeed-pers-lessons__item rfeed-pers-lessons__item--completed"
                :class="{ hidden: !showPathways }"
                v-for="completedPathway in completedPathways"
            >
                <div class="rfeed-pers-lessons__category">{{ completedPathway.name }}</div>
                <div class="rfeed-pers-lessons__name">{{ completedPathway.short_description }}</div>
                <a click.stop="#" class="rfeed-pers-lessons__completed">Lesson Completed!</a>
            </div>

            <a href="#" v-if="showPathways" class="rfeed-pers-lessons__view"
                >View All Completed Lessons</a
            >
        </template>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import cookie from '../../../mixins/cookie';

export default {
    name: 'feed-pathways',
    mixins: [cookie],
    data() {
        return {
            completedPathways: [],
            guestPathways: [],
            showPathways: true
        };
    },
    mounted() {
        this.showPathways = this.getCookie('showPathways');
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {
        togglePathways() {
            this.setCookie('showPathways', !this.showPathways, 232000);
            this.showPathways = !this.showPathways;
        }
    },
    watch: {
        'guest.pathways': {
            handler(pathways) {
                this.completedPathways = _.reject(pathways, ['pivot.completed_at', null]);
                this.guestPathways = _.filter(pathways, ['pivot.completed_at', null]);
            },
            deep: true,
            immediate: true
        }
    }
};
</script>

<style lang="scss">
.rfeed-pers-lessons__item {
    opacity: 1;
    transition-property: all;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    height: auto;
    padding: 12px;
    margin-bottom: 8px;

    &.hidden {
        opacity: 0;
        height: 0;
        padding: 0;
        margin-bottom: 0;
    }
}
</style>
