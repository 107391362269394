<template>
    <div class="resource__head">
        <h1 class="h2 resource__title" v-html="content.title"></h1>
        <p class="mt--050 p p--lg resource__subtitle" v-html="content.description"></p>

        <div class="grid-row grid-row--smg grid-row--aic grid-row--jcc">
            <content-actions></content-actions>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Time from '../../../mixins/Time';

export default {
    name: 'content-header',
    props: [''],
    mixins: [Time],
    mounted() {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            content: 'content',
            guest: 'guest',
            pathway: 'pathway'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss"></style>
