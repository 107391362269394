<template>
    <div class="grid-col grid-col--8 grid-col--md-10 grid-col--sm-12" id="feedContainer">

        <feed-welcome></feed-welcome>

        <lesson-card v-if="featuredPathway" :pathway="featuredPathway"></lesson-card>

        <featured-card v-if="featuredContent" :featured-content="featuredContent"></featured-card>

        <saved-card v-if="savedContent" :saved-content="savedContent"></saved-card>

        <template v-for="item in feedContent">
            <content-card :content="item"></content-card>
        </template>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import {showAt, hideAt} from 'vue-breakpoints'
import Tour from "../../../mixins/Tour";

export default {
    name: 'feed-timeline',
    props: ['featuredPathway', 'featuredContent', 'savedContent'],
    components: {hideAt, showAt},
    mixins: [Tour],
    mounted() {
        if (APP_ENV !== 'testing' && APP_ENV !== 'dusk') {
            setTimeout(() => {
                if (! this.tourIsCompleteFor('feed', this.guest.id)) {
                    this.$nextTick(() => {
                        const tour = this.$shepherd({
                            useModalOverlay: true,
                            defaultStepOptions: {
                                scrollTo: { behavior: 'smooth', block: 'center' },
                                popperOptions: {
                                    modifiers: [
                                        { name: 'offset', options: { offset: [0, 20] } },
                                    ]
                                }
                            },
                        });

                        tour.on('complete', () => {
                            this.completeTourForGuest('feed', this.guest.id);
                        })

                        this.getTourStepsForFeed(tour, this.featuredPathway, this.featuredContent).forEach(step => {
                            tour.addStep(step);
                        })

                        tour.start();
                    });
                }
            }, 100)
        }
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            feedContent: 'feedContent'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss">
.rfeed-featured__action--discard {
    margin-top: 2px;
    background-image: url('data:image/svg+xml;utf8,<svg enable-background="new 0 0 25 25" viewBox="0 0 25 25"  fill="%23FFFFFF" xmlns="http://www.w3.org/2000/svg"><path d="m17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11.5c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"/><path d="m15.75 20c-.192 0-.384-.073-.53-.22-.293-.293-.293-.768 0-1.061l3.5-3.5c.293-.293.768-.293 1.061 0s.293.768 0 1.061l-3.5 3.5c-.147.147-.339.22-.531.22z"/><path d="m19.25 20c-.192 0-.384-.073-.53-.22l-3.5-3.5c-.293-.293-.293-.768 0-1.061s.768-.293 1.061 0l3.5 3.5c.293.293.293.768 0 1.061-.147.147-.339.22-.531.22z"/><path d="m10.51 13h-5.76c-.414 0-.75-.336-.75-.75v-4.5c0-.414.336-.75.75-.75h14.5c.414 0 .75.336.75.75v1.18c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-.43h-13v3h5.01c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m9.05 15.99h-4.25c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.25c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m8.79 18.99h-3.99c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h3.99c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m9.59 22h-6.84c-1.517 0-2.75-1.233-2.75-2.75v-16.5c0-1.517 1.233-2.75 2.75-2.75h18.5c1.517 0 2.75 1.233 2.75 2.75v8.17c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.17c0-.689-.561-1.25-1.25-1.25h-18.5c-.689 0-1.25.561-1.25 1.25v16.5c0 .689.561 1.25 1.25 1.25h6.84c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m23.25 5h-22.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h22.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></svg>');
}

.rfeed-article__action--discard {
    margin-top: 2px;
    background-image: url('data:image/svg+xml;utf8,<svg enable-background="new 0 0 25 25" viewBox="0 0 25 25"  fill="%2362727B" xmlns="http://www.w3.org/2000/svg"><path d="m17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11.5c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"/><path d="m15.75 20c-.192 0-.384-.073-.53-.22-.293-.293-.293-.768 0-1.061l3.5-3.5c.293-.293.768-.293 1.061 0s.293.768 0 1.061l-3.5 3.5c-.147.147-.339.22-.531.22z"/><path d="m19.25 20c-.192 0-.384-.073-.53-.22l-3.5-3.5c-.293-.293-.293-.768 0-1.061s.768-.293 1.061 0l3.5 3.5c.293.293.293.768 0 1.061-.147.147-.339.22-.531.22z"/><path d="m10.51 13h-5.76c-.414 0-.75-.336-.75-.75v-4.5c0-.414.336-.75.75-.75h14.5c.414 0 .75.336.75.75v1.18c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-.43h-13v3h5.01c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m9.05 15.99h-4.25c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.25c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m8.79 18.99h-3.99c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h3.99c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m9.59 22h-6.84c-1.517 0-2.75-1.233-2.75-2.75v-16.5c0-1.517 1.233-2.75 2.75-2.75h18.5c1.517 0 2.75 1.233 2.75 2.75v8.17c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.17c0-.689-.561-1.25-1.25-1.25h-18.5c-.689 0-1.25.561-1.25 1.25v16.5c0 .689.561 1.25 1.25 1.25h6.84c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m23.25 5h-22.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h22.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></svg>');
}

.rfeed-article__action--discard:hover,
.rfeed-featured__action--discard:hover {
    background-image: url('data:image/svg+xml;utf8,<svg enable-background="new 0 0 25 25" viewBox="0 0 25 25"  fill="%231097E2" xmlns="http://www.w3.org/2000/svg"><path d="m17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11.5c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"/><path d="m15.75 20c-.192 0-.384-.073-.53-.22-.293-.293-.293-.768 0-1.061l3.5-3.5c.293-.293.768-.293 1.061 0s.293.768 0 1.061l-3.5 3.5c-.147.147-.339.22-.531.22z"/><path d="m19.25 20c-.192 0-.384-.073-.53-.22l-3.5-3.5c-.293-.293-.293-.768 0-1.061s.768-.293 1.061 0l3.5 3.5c.293.293.293.768 0 1.061-.147.147-.339.22-.531.22z"/><path d="m10.51 13h-5.76c-.414 0-.75-.336-.75-.75v-4.5c0-.414.336-.75.75-.75h14.5c.414 0 .75.336.75.75v1.18c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-.43h-13v3h5.01c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m9.05 15.99h-4.25c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.25c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m8.79 18.99h-3.99c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h3.99c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m9.59 22h-6.84c-1.517 0-2.75-1.233-2.75-2.75v-16.5c0-1.517 1.233-2.75 2.75-2.75h18.5c1.517 0 2.75 1.233 2.75 2.75v8.17c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.17c0-.689-.561-1.25-1.25-1.25h-18.5c-.689 0-1.25.561-1.25 1.25v16.5c0 .689.561 1.25 1.25 1.25h6.84c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m23.25 5h-22.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h22.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></svg>');
}
</style>
