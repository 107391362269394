<template>
    <div class="onboard-quiz__head">
        <div class="grid-row grid-row--aic">
            <div class="grid-col grid-col--auto">
                <img
                    src="img/onboard-quiz/logo.png"
                    srcset="img/onboard-quiz/logo@2x.png"
                    width="100"
                    height="22"
                    alt="AARP"
                />
            </div>

            <div class="grid-col ta--center">
                <button
                    type="button"
                    id="onboard-quiz__back"
                    class="button button--xs onboard-quiz__back"
                    @click="$emit('goBack')"
                >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2.4"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <line x1="19" y1="12" x2="5" y2="12"></line>
                        <polyline points="11 18 5 12 11 6"></polyline></svg
                    ><span>Back</span>
                </button>
            </div>

            <div class="grid-col grid-col--3">
                <!--                <div class="onboard-progress">-->
                <!--                    <div class="onboard-progress__bar onboard-progress__bar&#45;&#45;left" style="width: 0%"></div>-->
                <!--                    <div class="onboard-progress__value">0%</div>-->
                <!--                    <div class="onboard-progress__bar onboard-progress__bar&#45;&#45;right" style="width: 100%"></div>-->
                <!--                </div>-->
            </div>

            <div class="grid-col ta--center">
                <button
                    type="button"
                    class="button button--xs onboard-quiz__back"
                    v-if="allowNext"
                    @click.once="loadNextNode"
                    dusk="nextNode"
                >
                    <span>Next</span>
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2.4"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <line x1="19" y1="12" x2="5" y2="12"></line>
                        <polyline
                            points="11 18 5 12 11 6"
                            style="transform: rotate(180deg); transform-origin: 50% 50%;"
                        ></polyline>
                    </svg>
                </button>
            </div>

            <div class="grid-col grid-col--auto" v-if="showResources !== false">
                <div class="onboard-saved">
                    <div class="onboard-saved__toggle">
                        <div class="onboard-saved__counter">
                            {{ guest ? guest.resource_ids.length : 0 }}
                        </div>
                        <div class="onboard-saved__label">Saved resources</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import collectorActions from '../../../mixins/collectorActions';
import { mapGetters } from 'vuex';

export default {
    name: 'pathway-nodes-header',
    props: ['showResources', 'contentType'],
    mixins: [collectorActions],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            content: 'content',
            pathway: 'pathway',
            node: 'node'
        }),
        allowNext() {
            return (
                !_.isEmpty(this.content) &&
                this.content.settings &&
                this.content.settings.allow_next &&
                !this.node.multi_content
            );
        }
    },
    methods: {
        loadNextNode() {
            this.skipContentAction(this.content.id, this.node.id, this.pathway.id);
            this.$emit('loadNext');
        }
    },
    watch: {}
};
</script>

<style lang="scss"></style>
