<template>
    <div class="interested">
        <h3>You’ll Also be Interested&nbsp;In</h3>

        <div class="mt--050 grid-row grid-row--smg">
            <div
                class="mt--100 grid-col grid-col--md-6 grid-col--xs-12"
                v-for="content in similarContent"
                :key="content.id"
            >
                <div class="interested-card">
                    <div
                        class="interested-card__figure"
                        v-if="content.content_type.name !== 'Video'"
                    >
                        <img :src="content.thumbnail_xs" width="370" height="200" alt="" />
                    </div>
                    <div class="interested-card__figure interested-card__figure--video" v-else>
                        <img :src="content.thumbnail_xs" width="370" height="200" alt="" />
                        <div class="interested-card__play"></div>
                    </div>

                    <h4 class="mt--100">{{ content.title }}</h4>
                    <p class="mt--050">{{ content.description }}</p>

                    <div class="mt--125 interested-card__foot">
                        <div class="interested-card__badge">{{ content.content_type.name }}</div>
                        <div class="interested-card__meta">
                            {{
                                content.content_type.name === 'Video'
                                    ? videoDuration(content.settings.video_duration)
                                    : ''
                            }}
                        </div>
                        <button
                            type="button"
                            class="interested-card__action interested-card__action--book"
                            :class="{
                                active:
                                    guest.resource_ids && guest.resource_ids.includes(content.id)
                            }"
                            @click="bookmarkAction(guest, content)"
                        ></button>
                        <button
                            type="button"
                            class="interested-card__action interested-card__action--share"
                        ></button>
                        <button
                            type="button"
                            class="interested-card__action interested-card__action--more"
                        ></button>
                    </div>
                    <a :href="`/content/${content.slug}`" class="interested-card__link"></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Time from '../../../mixins/Time';
import collectorActions from '../../../mixins/collectorActions';
import bookmarkActions from '../../../mixins/bookmarkActions';

export default {
    name: 'similar-content',
    props: ['similarContent'],
    mixins: [Time, collectorActions, bookmarkActions],
    mounted() {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss">
.interested-card__action--book.active {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="%2323202E" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>');
}
</style>
