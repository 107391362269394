<template>
    <div>
        <v-overlay :value="overlay" z-index="9999">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar color="success" top v-model="snackbar" :timeout="1500">
            Pathway updated!
            <v-icon dark>check_circle</v-icon>
        </v-snackbar>

        <v-snackbar color="success" top v-model="stateSnackbar" :timeout="1500">
            Pathway state updated!
            <v-icon dark>check_circle</v-icon>
        </v-snackbar>

        <v-snackbar color="error" top v-model="errorSnackbar" :timeout="6500">
            {{ errorMessage }}
        </v-snackbar>

        <div class="admin-header">
            <v-row>
                <div class="col-auto">
                    <back-button url="/admin/pathways"></back-button>
                </div>
                <div class="display-1 col-auto">
                    {{ existingPathway ? 'Edit ' + form.name : 'Create Pathway' }}
                </div>
                <v-spacer></v-spacer>
                <div class="grid-col grid-col--auto d-flex align-center">
                    <save-button @click="submit"></save-button>
                </div>
            </v-row>

            <v-row class="admin-header__tabs">
                <ul id="tabs">
                    <li :class="{ active: tab === 'Details' }" @click="tab = 'Details'">
                        Details
                    </li>

                    <li
                        :class="{ active: tab === 'Flow' }"
                        @click="tab = 'Flow'"
                        v-if="existingPathway"
                    >
                        Flow
                    </li>
                </ul>
            </v-row>
        </div>

        <v-row no-gutters v-if="tab === 'Details'">
            <v-alert type="error" v-if="form.errors.first('general')">
                {{ form.errors.first('general') }}
            </v-alert>

            <v-form ref="form" v-model="valid" style="width: 100%">
                <v-row no-gutters>
                    <v-col cols="6">
                        <v-col cols="12">
                            <v-textarea
                                required
                                label="Name"
                                placeholder="Enter the name of the pathway"
                                v-model="form.name"
                                :rules="[v => !!v || 'Please enter a name']"
                                :error-messages="form.errors.first('name')"
                                outlined
                            ></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea
                                label="Description"
                                placeholder="Add description"
                                v-model="form.description"
                                :error-messages="form.errors.first('description')"
                                outlined
                            ></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <topic-dropdown
                                v-model="form.topics"
                                :all-topics-data="allTopics"
                            ></topic-dropdown>
                        </v-col>

                        <v-col cols="12">
                            <save-button @click="submit" dusk="submitButton"></save-button>
                        </v-col>
                    </v-col>

                    <v-col cols="6" class="alt-form">
                        <v-col cols="12" v-if="form.id">
                            <div class="toggle-group ma-0" style="background: #fff">
                                <span>Status</span>
                                <v-btn
                                    depressed
                                    small
                                    :color="form.active ? 'error' : 'primary'"
                                    @click="toggleActive"
                                    class="py-2 my-2"
                                >
                                    {{ form.active ? 'Deactivate' : 'Activate' }}
                                </v-btn>
                            </div>
                            <small
                                class="status-hint"
                                v-text="
                                    form.active
                                        ? 'Clicking this will make the pathway unavailable for guests'
                                        : 'By clicking this guests will be able to access the pathway'
                                "
                            ></small>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                class="white-bg"
                                label="Slug"
                                persistent-hint
                                hint="This will be part of the URL you will share with guests. e.g. athena.website/slug"
                                placeholder=""
                                v-model="form.slug"
                                :rules="[v => !!v || 'Please enter a slug']"
                                :error-messages="form.errors.first('slug')"
                                outlined
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <div class="checkboxes-group mb-0">
                                <div class="checkbox">
                                    <v-checkbox
                                        name="faq_tab"
                                        hide-details
                                        v-model="form.settings.faq_tab"
                                        label="Has faq tab"
                                    ></v-checkbox>
                                </div>
                                <div class="checkbox">
                                    <v-checkbox
                                        name="resources_tab"
                                        hide-details
                                        v-model="form.settings.resources_tab"
                                        label="Has resources tab"
                                    ></v-checkbox>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="12" v-if="form.settings.faq_tab">
                            <v-text-field
                                class="white-bg"
                                outlined
                                name="faq_url"
                                v-model="form.settings.faq_url"
                                :error-messages="form.errors.first('settings.faq_url')"
                                label="Enter Faq site url"
                            ></v-text-field>
                        </v-col>
                    </v-col>
                </v-row>
            </v-form>
        </v-row>

        <v-row no-gutters v-if="tab === 'Flow' && existingPathway">
            <flow
                :pathway="existingPathway"
                :settings="settings"
                :types="types"
                @overlay="overlayEvent"
                :zip-file-names="zipFileNames"
                :all-topics="allTopics"
            ></flow>
        </v-row>
    </div>
</template>
<script>
import PathwayForm from '../../../classes/Form/PathwayForm';
import Time from '../../../mixins/Time';
import TopicDropdown from '../topic/TopicDropdown';
import Flow from './Flow';
import BackButton from '../buttons/BackButton';
import SaveButton from '../buttons/SaveButton';

export default {
    name: 'pathway-c-u',
    components: { SaveButton, BackButton, TopicDropdown, Flow },
    props: [
        'existingPathway',
        'authUser',
        'types',
        'originalContent',
        'settings',
        'zipFileNames',
        'allTopics'
    ],
    mixins: [Time],
    created() {
        if (this.existingPathway) {
            this.form = new PathwayForm(this.existingPathway);
        }

        let hash = window.location.hash;

        if (hash) {
            let tab = hash.slice(1);
            this.tab = tab.charAt(0).toUpperCase() + tab.slice(1);
        }

        this.$store.commit('SET_CONTENT_LIST', this.originalContent);
    },
    data() {
        return {
            tab: 'Details',
            form: new PathwayForm(),
            snackbar: false,
            overlay: false,
            valid: false,
            stateSnackbar: false,
            errorSnackbar: false,
            errorMessage: null
        };
    },
    methods: {
        overlayEvent(value) {
            this.overlay = value;
        },
        submit() {
            let valid = this.$refs.form.validate();

            if (valid) {
                this.overlay = true;
                if (this.existingPathway) {
                    this.form
                        .put('/admin/pathways/' + this.form.id)
                        .then(response => {
                            this.snackbar = true;
                            this.overlay = false;

                            this.form = new PathwayForm(response.pathway);
                        })
                        .catch(e => {
                            this.overlay = false;
                        });
                } else {
                    this.form
                        .post('/admin/pathways')
                        .then(response => {
                            if (response.pathway.id) {
                                window.location =
                                    '/admin/pathways/' + response.pathway.id + '/edit';
                            } else {
                                alert('something went wrong');
                                this.overlay = false;
                            }
                        })
                        .catch(e => {
                            this.overlay = false;
                        });
                }
            }
        },
        toggleActive() {
            let futureState = this.form.active ? 'deactivate' : 'activate';
            let prefix = this.form.active ? 'un' : '';

            if (
                confirm(
                    'Are you sure you want to ' +
                        futureState +
                        ' this pathway? This will make it ' +
                        prefix +
                        'available for guests'
                )
            ) {
                this.overlay = true;
                axios
                    .post('/admin/pathways/toggle_state/' + this.form.id)
                    .then(response => {
                        this.stateSnackbar = true;
                        this.overlay = false;
                        this.form = new PathwayForm(response.data.pathway);
                        this.existingPathway.active = response.data.pathway.active;
                    })
                    .catch(error => {
                        this.overlay = false;
                        this.errorSnackbar = true;

                        if (error.response.data.error) {
                            this.errorMessage =
                                'Cannot activate pathway: ' + error.response.data.message;
                        } else {
                            this.errorMessage = 'There was an issue activating this pathway';
                        }

                        if (error.response.data.errorNodes) {
                            this.tab = 'Flow';
                            this.$store.commit('SET_ERROR_NODES', error.response.data.errorNodes);
                        }
                    });
            }
        }
    },
    watch: {}
};
</script>

<style lang="scss">
.status-hint {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    padding: 0 12px;
}

.alt-form {
    background-color: #f5f8fa;
    padding: 32px;
    border-radius: 6px;
}

.white-bg {
    .v-input__slot {
        background: #fff !important;
    }
}
</style>
