<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div class="admin-header">
            <v-row>
                <div class="display-1 col-auto">Guests</div>
            </v-row>
        </div>

        <div class="admin-table">
            <v-data-table
                :headers="headers"
                :items="guests"
                :options.sync="options"
                :footer-props="{ 'items-per-page-options': [20, 50, 75, 100] }"
                :loading="loading"
                :server-items-length="totalGuests"
                :items-per-page="20"
                class="elevation-1"
            >
                <template v-slot:no-data>
                    <div>No data</div>
                </template>
                <template v-if="guests.length" v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="item in items" :key="item.uuid">
                            <td>{{ item.name ? item.name : 'N/A' }}</td>
                            <td>
                                <v-row no-gutters align="center">
                                    <edit-button
                                        :url="`/admin/guests/${item.id}/actions`"
                                        text="Actions"
                                    ></edit-button>
                                </v-row>
                            </td>
                            <td>{{ item.email ? item.email : 'N/A' }}</td>
                            <td>{{ renderLocalTime(item.created_at) }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>
<script>
import Time from '../../../mixins/Time';

export default {
    props: ['authUser'],
    mixins: [Time],
    mounted() {
        this.getDataFromApi();
    },
    data() {
        return {
            guests: [],
            loading: false,
            overlay: false,
            totalGuests: 0,
            headers: [
                { text: 'Name', value: 'name', sortable: true },
                { text: 'Actions', value: '', sortable: false },
                { text: 'Email', value: 'email', sortable: true },
                { text: 'Created at', value: 'created_at', sortable: true }
                // { text: 'Actions', value: 'actions', sortable: false }
            ],
            options: { sortDesc: [true], sortBy: ['created_at'] }
        };
    },
    methods: {
        getDataFromApi: _.debounce(function() {
            this.loading = true;
            this.overlay = true;

            let endpoint = '/admin/guests/json';
            let descendingQuery =
                this.options.sortDesc[0] !== undefined
                    ? '&descending=' + this.options.sortDesc[0]
                    : '';
            let queries =
                '?page=' +
                this.options.page +
                '&rowsPerPage=' +
                this.options.itemsPerPage +
                '&sortBy=' +
                this.options.sortBy +
                descendingQuery;

            axios
                .get(endpoint + queries)
                .then(response => {
                    this.guests = response.data.data;
                    this.totalGuests = response.data.total;
                    this.loading = false;
                    this.overlay = false;
                })
                .catch(function(error) {
                    console.error(error);
                });
        }, 100)
    },
    watch: {
        options: {
            handler() {
                this.getDataFromApi();
            },
            deep: true
        }
    }
};
</script>
