<template>
    <div class="row grid-row--aic">
        <loading :active="overlay" />
        <main-header></main-header>
        <div class="mt--300 grid-col grid-col--12 removed--xs"></div>

        <div class="activity-tabs">
            <div class="grid-cont">
                <div class="grid-row grid-row--smg">
                    <ul id="tabs">
                        <li :class="{ active: tab === 'Saved' }" @click="switchTab('Saved')">
                            Saved
                        </li>

                        <li :class="{ active: tab === 'Viewed' }" @click="switchTab('Viewed')">
                            Viewed
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="grid-cont tab-items__cont">
            <div class="grid-row grid-row--smg">
                <div class="grid-col">
                    <div class="settings-search">
                        <div class="input">
                            <input
                                type="text"
                                placeholder="Search resources..."
                                v-model="searchText"
                                @input="search"
                                dusk="contentSearch"
                                class="input__input input__input--search settings-search__input"
                            />
                            <small
                                v-show="this.searchText.length <= 3 && this.searchText.length > 0"
                                >Type at least 3 letters to search</small
                            >
                        </div>
                        <div class="search__reset" v-if="this.searchText.length > 3">
                            <button class="button--xxs button mt--050" @click="resetSearch">
                                Reset search
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <template v-if="emptySearchData">
                <div class="grid-row grid-row--smg tab-cont" :class="{ active: tab === 'Saved' }">
                    <div
                        class="grid-col grid-col--12"
                        v-for="(savedData, contentType) in savedContent"
                    >
                        <p class="p mb--100 mt--300 p-width--100">
                            {{ contentType.toUpperCase() + 'S' }}
                        </p>

                        <div
                            class="mb--100"
                            :key="`saved-${content.id}`"
                            v-for="content in savedData"
                        >
                            <settings-card :content="content"></settings-card>
                        </div>
                    </div>
                </div>

                <div class="grid-row grid-row--smg tab-cont" :class="{ active: tab === 'Viewed' }">
                    <div
                        class="grid-col grid-col--12"
                        v-for="(viewedData, contentType) in viewedContent"
                        v-if="viewedData.length"
                    >
                        <p class="p mb--100 mt--300 p-width--100">
                            {{ contentType.toUpperCase() + 'S' }}
                        </p>

                        <div
                            class="mb--100"
                            :key="`viewed-${content.id}`"
                            v-for="content in viewedData"
                        >
                            <settings-card :content="content"></settings-card>
                        </div>

                        <div class="grid-row grid-row--jcc mb--200">
                            <button
                                :id="`loadMoreButton-${contentType}`"
                                :dusk="`loadMoreButton-${contentType}`"
                                class="button button--xxs button--primary"
                                @click="loadMoreContent(contentType)"
                                v-if="viewedData.length % 10 === 0"
                            >
                                Load more
                            </button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="grid-row grid-row--smg tab-cont active">
                    <div class="grid-col grid-col--12" v-for="(data, contentType) in searchData">
                        <p class="p mb--100 mt--300 p-width--100">
                            {{ contentType.toUpperCase() + 'S' }}
                        </p>

                        <div class="mb--100" :key="`search-${content.id}`" v-for="content in data">
                            <settings-card :content="content"></settings-card>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <pathway-footer></pathway-footer>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import FuseSearch from '../../../classes/FuseSearch';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    name: 'activity-view',
    props: ['originalGuest', 'allStaticContent', 'originalViewedContent', 'originalSavedContent'],
    mixins: [collectorActions],
    components: { Loading },
    mounted() {
        let hash = window.location.hash;

        if (hash) {
            let tab = hash.slice(1);
            this.tab = tab.charAt(0).toUpperCase() + tab.slice(1);
        }

        this.$store.commit('SET_GUEST', this.originalGuest);
        this.$store.commit('SET_STATIC_CONTENT', this.allStaticContent);
        this.$store.commit('SET_BACK_TO_FEED', true);
        this.openActivityAction();
    },
    data() {
        return {
            tab: 'Saved',
            viewedContent: this.originalViewedContent,
            savedContent: this.originalSavedContent,
            searchText: '',
            searchData: [],
            overlay: false
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        }),
        emptySearchData() {
            return _.isEmpty(this.searchData);
        }
    },
    methods: {
        search: _.debounce(async function() {
            this.overlay = true;
            if (this.searchText && this.searchText.length > 3) {
                this.searchData = [];

                let result = await new FuseSearch(this.tab).search(this.searchText);

                if (result.length) {
                    result.forEach(item => {
                        if (item.score <= 0.8) {
                            this.searchData.push(item.item);
                        }
                    });

                    this.searchData = _.groupBy(this.searchData, 'content_type.name');
                    this.searchActivityAction(this.tab, this.searchText, this.searchData);
                }
                this.overlay = false;
            } else {
                this.searchData = [];
                this.overlay = false;
            }
        }, 400),
        switchTab(tabName) {
            this.tab = tabName;
            this.searchData = [];
            this.searchText = '';
        },
        resetSearch() {
            this.searchData = [];
            this.searchText = '';
        },
        loadMoreContent(type) {
            this.overlay = true;
            axios
                .get('/activity/load_more', {
                    params: {
                        type: type,
                        tab: this.tab,
                        skip: this.viewedContent[type].length
                    }
                })
                .then(res => {
                    if (this.tab === 'Viewed') {
                        res.data.forEach(content => {
                            this.viewedContent[type].push(content);
                        });
                    }

                    this.overlay = false;
                });
        }
    },
    watch: {}
};
</script>

<style lang="scss">
.activity-tabs {
    background: #fff;
    padding: 20px 0 0;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 0 4px #999;
    margin-bottom: 40px;
}

#tabs {
    display: flex;
    flex-wrap: wrap;

    li {
        display: inline-block;
        cursor: pointer;
        margin-right: 30px;
        font-size: 1.2rem;
        padding: 0 3px 2px;

        &.active {
            border-bottom: 2px solid #3397e2;
        }
    }
}

.tab-cont {
    visibility: hidden;
    height: 0;
    opacity: 0;
    transition: all 0.1s;

    &.active {
        visibility: visible;
        height: auto;
        opacity: 1;
    }
}

.p-width--100 {
    width: 100%;
}

.search__reset {
    display: flex;
    justify-content: flex-end;
}
</style>
